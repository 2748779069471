import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./dashNavbar.css";
function DashNavbar() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  // const { dispatch } = useContext(DarkModeContext);
  // console.log("compcamp",user.comptopics);
  // console.log("compegrade",user.grade);
  // console.log("compe_id",user._id);

  const handleLogout = async () => {
    // dispatch({ type: "LOGOUT_START" });
    try {
      localStorage.removeItem("user");
      const res = await axios.post(
        "https://api.studentrays.com/api/auth/logout"
      ); // Send a request to your backend logout endpoint
      if (res.status === 200) {
        // dispatch({ type: "LOGOUT_SUCCESS" });
        navigate("/");
        window.location.reload();
      } else {
        // dispatch({ type: "LOGOUT_FAILURE" });
        console.log("Logout Failed");
      }
    } catch (err) {
      // dispatch({ type: "LOGOUT_FAILURE" });
      console.log(err);
    }
  };

  return (
    <Navbar expand="lg" className="custom-navbar bg-body-tertiary">
      <Navbar.Brand style={{ width: "unset" }} href="/dashboard/">
        StudentRays
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto d-flex align-items-center">
          <Navbar.Text className="me-3">
            User: <a href="/profile">{user.username}</a>
          </Navbar.Text>

          {/* This button will be hidden on large screens and shown on small screens */}
          <Nav className="d-lg-none">
            <button className="buttn" onClick={handleLogout}>
              Logout
            </button>
          </Nav>
        </Nav>

        {/* This button will be visible on large screens */}
        <Nav className="d-none d-lg-flex pe-2" >
          <Button className="buttn ms-3" onClick={handleLogout}>
            Logout
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default DashNavbar;
