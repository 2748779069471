
// import React, { useState, useEffect } from "react";
// import useFetch from "../../../hooks/useFetch";
// // import { AuthContext } from "../../../context/AuthContext";
// // import { useContext } from "react";
// import "./Quest.css";

// function Questionnaire(props) {
//   const { data,  error } = useFetch(`https://api.studentrays.com/api/details/${props.miniTopicid}`);
//   let { data: user } = useFetch(`https://api.studentrays.com/api/users/${props.userID}`);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const allQuestions = props.myQuestion;
//   const lengthOfQuestions = allQuestions.length;
//   console.log("lengthOfQuestions",lengthOfQuestions);
//   const [answers, setAnswers] = useState(Array(lengthOfQuestions-1).fill(""));
//   const [questions, setQuestions] = useState(props.myQuestion);
//   const [totalScore, setTotalScore] = useState(0);
//   const [submitted, setSubmitted] = useState(false);
//   const [matchingComptopics, setMatchingComptopics] = useState([]);
//   const [apiUrl] = useState(`https://api.studentrays.com/api/comptopics/${props.userID}`);
//   const currentQuestion = questions[currentQuestionIndex];

//   console.log("user comptopics", user.comptopics);
//   const comp = user.comptopics;
//   // console.log("user m", user);
//   // console.log("user name", user.username);
//   useEffect(() => {
//     if (data.miniTopics && comp) {
//       const matching = data.miniTopics.map((oneData) => {
//         return comp.filter((comptopic) => comptopic.subtopicId === oneData);
//       });
//       console.log("matching",matching);
//       const flattenedMatching = matching.flat(); // Flatten the array of arrays
//       setMatchingComptopics(flattenedMatching);
//     }
//   }, [data.miniTopics, comp]);
//   console.log("matchingComptopics", matchingComptopics);
//   const subtopicIds = matchingComptopics.map((item) => item.subtopicId);
//   console.log("subtopicIds",subtopicIds);


//   const handleNextClick = () => {
//     const currentQuestion = questions[currentQuestionIndex];

//     if (answers[currentQuestionIndex] !== "") {
//       if (currentQuestionIndex < questions.length - 1) {
//         setCurrentQuestionIndex(currentQuestionIndex + 1);
//       } else {
//         calculateTotalScore();
//         setSubmitted(true);
//         sendData();
//       }
//     }
//   };

//   const handleOptionChange = (e) => {
//     const { value } = e.target;
//     answers[currentQuestionIndex] = value;
//     setAnswers([...answers]);
//   };

//   const calculateTotalScore = () => {
//     let newTotalScore = 0;

//     for (let i = 0; i < answers.length; i++) {
//       const selectedOption = answers[i];
//       const question = questions[i];
//       if (selectedOption === question.correctAnswer) {
//         newTotalScore += 1;
//       }
//     }

//     setTotalScore(newTotalScore);
//   };

//   const isButtonDisabled = answers[currentQuestionIndex] === "";
//   const sendData = () => {
//     const dataToSend = {
//       subtopicId: props.currentId,
//       questionId: "id",
//       savedScore: totalScore, // Assuming totalScore is in scope
//     };
//     if (Array.isArray(subtopicIds) && subtopicIds.includes(props.currentId)) {
//       console.log(" is already present in minitopic.");
//     } else {
//       console.log(" is not present in minitopic.");
//       fetch(apiUrl, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(dataToSend),
//       })
//         .then((response) => {
//           if (!response.ok) {
//             throw new Error("Network response was not ok");
//           }
//           return response.json();
//         })
//         .then((data) => {
//           // Handle the response data if needed
//           console.log("Data sent successfully:", data);
//         })
//         .catch((error) => {
//           // Handle any errors that occurred during the fetch
//           console.error("Error sending data:", error);
//         });
//     }
    

//   };
  
//   return (
//     <div>
//       <div className="Qtop">
//         {currentQuestion && (
//           <div>
//             <p>{currentQuestion.question}</p>
//             <ul>
//               {currentQuestion.options.map((option, index) => (
//                 <li className="inpitli" key={index}>
//                   <label>
//                     <input
//                       className="inpitForm"
//                       type="radio"
//                       name="options"
//                       value={option}
//                       checked={answers[currentQuestionIndex] === option}
//                       onChange={handleOptionChange}
//                       required // Make input required
//                     />
//                     {option}
//                   </label>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         )}

//         {submitted ? (
//           <div>
//             <p className="submitResult">You have scored: {totalScore}</p>
//             <p className="submitResult">You can try again</p>

//             <button className="nextButton" onClick={() => window.location.reload()}>
//               Try Again
//             </button>
//           </div>
//         ) : (
//           <button className="nextButton" onClick={handleNextClick} disabled={isButtonDisabled}>
//             {currentQuestionIndex === questions.length - 1 ? "Submit" : "Next"}
//           </button>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Questionnaire;
import React, { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import "./Quest.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function Questionnaire(props) {
  const { data } = useFetch(`https://api.studentrays.com/api/details/${props.miniTopicid}`);
  let { data: user } = useFetch(`https://api.studentrays.com/api/users/${props.userID}`);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const allQuestions = props.myQuestion;
  const lengthOfQuestions = allQuestions.length;
  const [answers, setAnswers] = useState(Array(lengthOfQuestions).fill(""));
  const [questions, setQuestions] = useState(props.myQuestion);
  const [totalScore, setTotalScore] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [matchingComptopics, setMatchingComptopics] = useState([]);
  const [apiUrl] = useState(`https://api.studentrays.com/api/comptopics/${props.userID}`);
  const currentQuestion = questions[currentQuestionIndex];
  
  const comp = user?.comptopics || [];

  useEffect(() => {
    if (data.miniTopics && comp) {
      const matching = data.miniTopics.map((oneData) => {
        return comp.filter((comptopic) => comptopic.subtopicId === oneData);
      });
      const flattenedMatching = matching.flat(); // Flatten the array of arrays
      setMatchingComptopics(flattenedMatching);
    }
  }, [data.miniTopics, comp]);

  const subtopicIds = matchingComptopics.map((item) => item.subtopicId);

  const handleNextClick = () => {
    if (answers[currentQuestionIndex] !== "") {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        calculateTotalScore();
        setSubmitted(true);
        sendData();
      }
    }
  };

  const handleOptionChange = (e) => {
    const { value } = e.target;
    answers[currentQuestionIndex] = value;
    setAnswers([...answers]);
  };

  const calculateTotalScore = () => {
    let newTotalScore = 0;
    for (let i = 0; i < answers.length; i++) {
      const selectedOption = answers[i];
      const question = questions[i];
      if (selectedOption === question.correctAnswer) {
        newTotalScore += 1;
      }
    }
    setTotalScore(newTotalScore);
  };

  const sendData = () => {
    const dataToSend = {
      subtopicId: props.currentId,
      questionId: "id",
      savedScore: totalScore,
    };
    if (Array.isArray(subtopicIds) && subtopicIds.includes(props.currentId)) {
      console.log(" is already present in minitopic.");
    } else {
      console.log(" is not present in minitopic.");
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Data sent successfully:", data);
        })
        .catch((error) => {
          console.error("Error sending data:", error);
        });
    }
  };

  const isButtonDisabled = answers[currentQuestionIndex] === "";

  return (
    <div className="container">
      <div className="row justify-content-center mt-5 mb-5">
        <div className="col-lg-8 col-md-10 col-sm-12">
          <div className="card p-4">
            {currentQuestion && (
              <div>
                <h5>{currentQuestion.question}</h5>
                <ul className="list-group" style={{"--bs-list-group-border-color": "none"}}>
                  {currentQuestion.options.map((option, index) => (
                    <li className="list-group-item" key={index}>
                      <label>
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          name="options"
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={handleOptionChange}
                          required
                        />
                        {option}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {submitted ? (
              <div className="mt-4">
                <p className="text-success">You have scored: {totalScore}</p>
                <p>You can try again</p>
                <button
                  className="btn btn-primary"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            ) : (
              <button
                className="btn btn-primary mt-4"
                onClick={handleNextClick}
                disabled={isButtonDisabled}
              >
                {currentQuestionIndex === questions.length - 1 ? "Submit" : "Next"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Questionnaire;
