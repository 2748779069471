import React from 'react';
import axios from 'axios';

function downloadExcel(studentId) {
    console.log("came 5");
    
  axios({
    url: `https://api.studentrays.com/api/datas/generate-report/${studentId}`,
    method: 'GET',
    responseType: 'blob', // Ensure the response is a Blob (binary large object)
  })
    .then((response) => {
        console.log("came 13");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'User_Report.xlsx'); // Name of the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the link element
    })
    .catch((error) => {
      console.error('Error downloading the Excel file:', error);
    });
}

const ExcelDownloadButton = ({ studentId }) => {
  return (
    <div>
      <button onClick={() => downloadExcel(studentId)}>
        Download Excel Report
      </button>
    </div>
  );
};

export default ExcelDownloadButton;
