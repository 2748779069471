import "./datatable.css";
import { DataGrid } from "@mui/x-data-grid";
// import { userColumns, userRows } from "./datatablesource";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import axios from "axios";

const Datatable = ({ columns, teacherAssigned, batch }) => {
  // console.log("id", id);
  // console.log("columns, teacherAssigned, batch",columns, teacherAssigned, batch);
  
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const { user } = useContext(AuthContext);
  // console.log("teacheruser",user.teacherSection);
  // const paths = location.pathname.split("/")[2];
  const [list, setList] = useState();
  const [datas, setDatas] = useState();

  const { data, loading, error } = useFetch(
    `https://api.studentrays.com/api/users/findstudents/${teacherAssigned}/${batch}`
    // `https://api.studentrays.com/api/users/${id}`
  );
  console.log("Datatable Data", data);
  useEffect(() => {
    setDatas(data);
  }, [data]);
  // useEffect(() => {
  //   if (data) {
  //     const uniqueData = data.filter((v, i, a) => a.findIndex(t => (t._id === v._id)) === i);
  //     setDatas(uniqueData);
  //   }
  // }, [data]);
  useEffect(() => {
    console.log("datas", datas);
  }, [datas]);

  // const studentObjects = data.filter(item => item.isStudent);
  // console.log("studentObjects",studentObjects);
  // const filteredData = studentObjects && studentObjects.filter((row) => row.course === "CBSE");
  // const filteredData = studentObjects && studentObjects.filter((row) => row.teacherAssigned === id);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/${path}/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="datatable">
        <div className="datatableTitle">{batch}</div>
        {/* <div className="datatableTitle">{path}</div> */}
        {datas && (
          <DataGrid
            className="datagrid"
            // rows={filteredData}
            rows={datas}
            columns={columns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
            getRowId={(row) => row._id}
          />
        )}
      </div>
    </>
  );
};

export default Datatable;
