// import React from "react";
// import DashNavbar from "./navbar/DashNavbar";
// import Footer from "./footer/Footer";
// import { useContext, useEffect } from "react";
// import { AuthContext } from "./../../context/AuthContext";
// import VideoPages from "../dashboard/header/VideoPages";
// import DashboardUserData from "../components/dashboardUserData/DashboardUserData";
// import "./paydashboard.css";
// import { useState } from "react";
// import axios from "axios";
// import OrderButton from "../dashboard/Razorpay_button/OrderButton";
// import EmailButton from "../dashboard/EmailButton/EmailButton";
// import Form from "react-bootstrap/Form";
// import moment from "moment";

// const Paydashboard = () => {
//   const { user } = useContext(AuthContext);
//   console.log("user", user);
//   const [batch, setBatch] = useState();
//   const [subject, setSubject] = useState();
//   const [moneypay, setMoneypay] = useState();
//   const [sciPay, setSciPay] = useState();
//   const [matPay, setMatPay] = useState();
//   const [bothPay, setBothPay] = useState();
//   const [datas, setDatas] = useState([]);
//   const [events, setEvents] = useState([]);
//   const [ongoingEvent, setOngoingEvent] = useState(null);
//   const [UpcomingCounselorMarker, setUpcomingCounselorMarker] = useState(null);
//   // const paydataToSend = {
//   //   "course": user.course,
//   //   "grade": user.grade
//   // }
//   useEffect(() => {
//     const fetchPayData = async () => {
//       try {
//         const paydataToSend = {
//           course: user.course,
//           grade: user.grade,
//         };

//         const payresponse = await axios.post(
//           "https://api.studentrays.com/api/users/calculatePay",
//           paydataToSend
//         );
//         console.log("payresponse", payresponse.data);
//         setSciPay(payresponse.data.sciPay);
//         setMatPay(payresponse.data.matPay);
//         setBothPay(payresponse.data.bothPay);
//         // setMoneypay(payresponse.data.pay);
//       } catch (error) {
//         console.error("Error fetching pay data:", error);
//       }
//     };

//     fetchPayData();
//   }, [user.course, user.grade]);
//   useEffect(() => {
//     const fetchCalenderData = async () => {
//       try {
//         // const paydataToSend = {
//         //   course: user.course,
//         //   grade: user.grade,
//         // };
//         const batch = "councellor";
//         const response = await axios.get(
//           `https://api.studentrays.com/api/calender/${user.teacherAssigned}/${batch}/${user._id}`
//         );
//         console.log("Calender response", response.data);
//         setDatas(response.data);
//         // setSciPay(payresponse.data.sciPay);
//         // setMatPay(payresponse.data.matPay);
//         // setBothPay(payresponse.data.bothPay);
//         // setMoneypay(payresponse.data.pay);
//       } catch (error) {
//         console.error("Error fetching pay data:", error);
//       }
//     };

//     fetchCalenderData();
//   }, [user]);
//   useEffect(() => {
//     if (datas && datas.length > 0) {
//       const currentTime = moment(); // Use the provided current time for testing
//       // const currentTime = moment("2024-07-10T00:30");
//       // const currentTime = moment(
//       //   "Jul 11 2024 18:00:00 GMT+0530",
//       //   "MMM DD YYYY HH:mm:ss [GMT]ZZ"
//       // );
//       let nextClassBatch = null;
//       let ongoingEventFound = false;

//       const updatedEvents = datas.map((event, index) => {
//         const { Date: eventDate, startTime, endTime, batch, joinUrl } = event;

//         const [year, month, day] = eventDate.split("-").map(Number);
//         const [startHour, startMinute] = startTime.split(":").map(Number);
//         const [endHour, endMinute] = endTime.split(":").map(Number);

//         const start = moment({
//           year,
//           month: month - 1,
//           day,
//           hour: startHour,
//           minute: startMinute,
//         });
//         const end = moment({
//           year,
//           month: month - 1,
//           day,
//           hour: endHour,
//           minute: endMinute,
//         });

//         if (currentTime.isBetween(start, end, null, "[]")) {
//           setOngoingEvent({
//             id: index + 1,
//             title: batch,
//             start: start.toDate(),
//             end: end.toDate(),
//             url: joinUrl,
//           });
//           ongoingEventFound = true;
//         }

//         // Check for upcoming counselor meeting
//         if (currentTime.isBefore(start) && batch === "councellor") {
//           const duration = moment.duration(start.diff(currentTime));
//           const hours = duration.hours();
//           const minutes = duration.minutes();
//           const days = duration.days();

//           let timeRemaining = "";
//           if (days > 0) {
//             timeRemaining = `${days} day(s)`;
//           } else if (hours > 0) {
//             timeRemaining = `${hours} hour(s)`;
//           } else if (minutes > 0) {
//             timeRemaining = `${minutes} minute(s)`;
//           }

//           setUpcomingCounselorMarker({
//             message: `You have a meeting with a councellor in ${timeRemaining}.`,
//             // start: start.toDate(),
//             start: start.toLocaleString(),
//           });
//         }

//         // if (
//         //   start.isAfter(currentTime) &&
//         //   (!nextClassTime || start.isBefore(nextClassTime))
//         // ) {
//         //   nextClassTime = start;
//         //   nextClassBatch = batch;
//         // }

//         return {
//           id: index + 1,
//           title: batch,
//           start: start.toDate(),
//           end: end.toDate(),
//           url: joinUrl,
//         };
//       });

//       setEvents(updatedEvents);

//       // if (!ongoingEventFound && nextClassTime) {
//       //   const duration = moment.duration(nextClassTime.diff(currentTime));
//       //   const days = Math.floor(duration.asDays());
//       //   const hours = duration.hours();
//       //   const minutes = duration.minutes();

//       //   const timeLeftMessage = `Time left for next class (${nextClassBatch}): ${
//       //     days > 0 ? days + " days " : ""
//       //   }${hours > 0 ? hours + " hours " : ""}${minutes} minutes`;
//       //   // setNextClass(timeLeftMessage);
//       // }
//     }
//   }, [datas]);

//   const saveBatch = async (e) => {
//     console.log(e);
//     const dataToSend = {
//       section: e,
//     };
//     try {
//       const response = await axios.put(
//         `https://api.studentrays.com/api/users/${user._id}`,
//         dataToSend
//       );
//       console.log("Data sent successfully:", response.data);
//       alert(
//         `You selected Batch ${e} successfully! You can now move to perchase course.`
//       );
//       setBatch(e);
//     } catch (err) {
//       console.error("Batch selection failed:", err);
//       if (err.response) {
//         console.error("Server error:", err.response.status, err.response.data);
//       } else if (err.request) {
//         console.error("Network error:", err.request);
//       } else {
//         console.error("General error:", err.message);
//       }
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault(); // Prevent the form from refreshing the page

//     const formData = new FormData(event.target);
//     const formProps = Object.fromEntries(formData.entries());

//     console.log(formProps); // Log the form data
//     // const batch = `${data.course}${data.grade}${formProps.subject}${data.section}`;
//     const batch =
//       `${user.course}${user.grade}${formProps.subject}${user.section}`.replace(
//         /\s+/g,
//         ""
//       );
//     const lowerCaseBatch = batch.toLowerCase();
//     console.log(lowerCaseBatch); // Outputs: "cbse8mata" // Outputs: "cbsc8bsci"

//     try {
//       const updatedBatch = [lowerCaseBatch];
//       const newBatch = lowerCaseBatch;
//       if (formProps.subject == "sci") {
//         setMoneypay(sciPay);
//         // const pay = sciPay;
//       } else if (formProps.subject == "mat") {
//         setMoneypay(matPay);
//         // const pay = matPay;
//       }

//       const batchdata = {
//         course: user.course,
//         grade: user.grade,
//         section: user.section,
//         subject: formProps.subject,
//         currentbatch: newBatch,
//         cost: moneypay,
//       };

//       console.log("batchdata", batchdata);

//       const batchResponse = await axios.post(
//         `https://api.studentrays.com/api/batchs/${user._id}`,
//         batchdata
//       );
//       console.log("batchResponse", batchResponse);

//       // Send the updated data back to the server
//       const updateResponse = await axios.put(
//         `https://api.studentrays.com/api/users/${user._id}`,
//         {
//           batch: updatedBatch,
//         }
//       );
//       if (formProps.subject === "sci") {
//         setSubject("Science");
//       } else {
//         setSubject("Maths");
//       }
//       // setSubject(formProps.subject);
//       console.log("updateResponse", updateResponse);
//       // setAllbatch(updatedBatch);
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   return (
//     <>
//       <DashNavbar />
//       <div className="container">
//         {user && (
//           <DashboardUserData
//             name={user.name}
//             course={user.course}
//             grade={user.grade}
//             phone={user.phone}
//             email={user.email}
//             state={user.state}
//           />
//         )}
//         {(UpcomingCounselorMarker || ongoingEvent) && (
//           <div className="joinClass">
//             {UpcomingCounselorMarker && (
//               <div className="joinClassUpper">
//                 <div className="joinClassInner">
//                   {UpcomingCounselorMarker.message} <br /> Meeting start at:
//                   {UpcomingCounselorMarker.start}
//                 </div>
//               </div>
//             )}
//             {ongoingEvent && (
//               <div>
//                 <p>Join Meeting with {ongoingEvent.title}</p>
//                 <a
//                   href={ongoingEvent.url}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <button className="btn btn-primary">Join</button>
//                 </a>
//               </div>
//             )}
//           </div>
//         )}
//         <div className="heading">Choose your Batch</div>
//         <div className="batchSelection">
//           <div className="batchTimeBox">
//             <span className="batchOption">Batch A</span>
//             <span>6:00 - 7:00 PM</span>
//             <button className="batchButton" onClick={() => saveBatch("A")}>
//               choose
//             </button>
//           </div>
//           <div className="batchTimeBox">
//             <span className="batchOption">Batch B</span>
//             <span>7:00 - 8:00 PM</span>
//             <button className="batchButton" onClick={() => saveBatch("B")}>
//               choose
//             </button>
//           </div>
//           <div className="batchTimeBox">
//             <span className="batchOption">Batch C</span>
//             <span>8:00 - 9:00 PM</span>
//             <button className="batchButton" onClick={() => saveBatch("C")}>
//               choose
//             </button>
//           </div>
//         </div>
//         <h2>Select Subject</h2>

//         <Form className="payForm" onSubmit={handleSubmit}>
//           {["radio"].map((type) => (
//             <div key={`inline-${type}`} className="selectSubject">
//               <div className="paysubjectSelection">
//                 <Form.Check
//                   inline
//                   label="Science"
//                   name="subject"
//                   type={type}
//                   id={`inline-${type}-1`}
//                   value="sci"
//                   style={{
//                     fontWeight: 600,
//                     display: "flex",
//                     gap: "20px",
//                     "align-items": "center",
//                     fontSize: "1.5rem",
//                   }}
//                 />
//                 <span>
//                   Payable amount: Rs <b>{sciPay}</b>
//                 </span>
//               </div>
//               <div className="paysubjectSelection">
//                 <Form.Check
//                   inline
//                   label="Maths"
//                   name="subject"
//                   type={type}
//                   id={`inline-${type}-2`}
//                   value="mat"
//                   style={{
//                     fontWeight: 600,
//                     display: "flex",
//                     gap: "20px",
//                     "align-items": "center",
//                     fontSize: "1.5rem",
//                   }}
//                 />
//                 <span>
//                   Payable amount: Rs <b>{matPay}</b>
//                 </span>
//               </div>
//               <div className="paysubjectSelection">
//                 <Form.Check
//                   inline
//                   label="Both"
//                   name="subject"
//                   type={type}
//                   id={`inline-${type}-2`}
//                   value="both"
//                   disabled
//                   style={{
//                     fontWeight: 600,
//                     display: "flex",
//                     gap: "20px",
//                     "align-items": "center",
//                     fontSize: "1.5rem",
//                   }}
//                 />
//                 <span>
//                   Payable amount: Rs <b>{bothPay}</b>
//                 </span>
//                 <span></span>
//               </div>
//             </div>
//           ))}
//           <button className="batchButton" type="submit">
//             Submit
//           </button>
//         </Form>

//         {batch && <p>your Selected Section is {batch}</p>}
//         {subject && <p>your Selected Subject is {subject}</p>}
//         {batch && subject ? (
//           <OrderButton
//             username={user.username}
//             emaildata={user.email}
//             amount={moneypay}
//             userdata={user}
//           />
//         ) : (
//           <p>Please Select Section and Subject to see payment Button</p>
//         )}

//         {/* <EmailButton /> */}
//         <div className="videoHead">Videos that may interest you</div>
//         <div className="playerAreea">
//           <VideoPages />
//           <VideoPages />
//           <VideoPages />
//           {/* <VideoPages /> */}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Paydashboard;
import React from "react";
import DashNavbar from "./navbar/DashNavbar";
import Footer from "./footer/Footer";
import { useContext, useEffect } from "react";
import { AuthContext } from "./../../context/AuthContext";
import VideoPages from "../dashboard/header/VideoPages";
import DashboardUserData from "../components/dashboardUserData/DashboardUserData";
import "./paydashboard.css";
import { useState } from "react";
import axios from "axios";
import OrderButton from "../dashboard/Razorpay_button/OrderButton";
import EmailButton from "../dashboard/EmailButton/EmailButton";
import Form from "react-bootstrap/Form";
import moment from "moment";

const Paydashboard = () => {
  const { user } = useContext(AuthContext);
  // console.log("user", user);
  const [batch, setBatch] = useState();
  const [subject, setSubject] = useState();
  const [section, setSection] = useState();
  const [moneypay, setMoneypay] = useState();
  const [sciPay, setSciPay] = useState();
  const [matPay, setMatPay] = useState();
  const [bothPay, setBothPay] = useState();
  const [datas, setDatas] = useState([]);
  const [events, setEvents] = useState([]);
  const [ongoingEvent, setOngoingEvent] = useState(null);
  const [UpcomingCounselorMarker, setUpcomingCounselorMarker] = useState(null);
  // const paydataToSend = {
  //   "course": user.course,
  //   "grade": user.grade
  // }
  useEffect(() => {
    const fetchPayData = async () => {
      try {
        const paydataToSend = {
          course: user.course,
          grade: user.grade,
        };

        const payresponse = await axios.post(
          "https://api.studentrays.com/api/users/calculatePay",
          paydataToSend
        );
        // console.log("payresponse", payresponse.data);
        setSciPay(payresponse.data.sciPay);
        setMatPay(payresponse.data.matPay);
        setBothPay(payresponse.data.bothPay);
        // setMoneypay(payresponse.data.pay);
      } catch (error) {
        console.error("Error fetching pay data:", error);
      }
    };

    fetchPayData();
  }, [user.course, user.grade]);
  useEffect(() => {
    const fetchCalenderData = async () => {
      try {
        // const paydataToSend = {
        //   course: user.course,
        //   grade: user.grade,
        // };
        const batch = "councellor";
        const response = await axios.get(
          `https://api.studentrays.com/api/calender/${user._id}`
        );
        // console.log("Calender response", response.data);
        setDatas(response.data);
        // setSciPay(payresponse.data.sciPay);
        // setMatPay(payresponse.data.matPay);
        // setBothPay(payresponse.data.bothPay);
        // setMoneypay(payresponse.data.pay);
      } catch (error) {
        console.error("Error fetching pay data:", error);
      }
    };

    fetchCalenderData();
  }, [user]);
  useEffect(() => {
    if (datas && datas.length > 0) {
      // console.log("11");

      const currentTime = moment(); // Use the provided current time for testing
      // const currentTime = moment("2024-07-10T00:30");
      // const currentTime = moment(
      //   "Jul 11 2024 18:00:00 GMT+0530",
      //   "MMM DD YYYY HH:mm:ss [GMT]ZZ"
      // );
      let nextClassBatch = null;
      let ongoingEventFound = false;

      const updatedEvents = datas.map((event, index) => {
        const { Date: eventDate, startTime, endTime, batch, joinUrl } = event;

        const [year, month, day] = eventDate.split("-").map(Number);
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);

        const start = moment({
          year,
          month: month - 1,
          day,
          hour: startHour,
          minute: startMinute,
        });
        const end = moment({
          year,
          month: month - 1,
          day,
          hour: endHour,
          minute: endMinute,
        });

        if (currentTime.isBetween(start, end, null, "[]")) {
          setOngoingEvent({
            id: index + 1,
            title: batch,
            start: start.toDate(),
            end: end.toDate(),
            url: joinUrl,
          });
          ongoingEventFound = true;
        }
// console.log("currentTime",currentTime);
// console.log("start",start);

        // Check for upcoming counselor meeting
        if (currentTime.isBefore(start) && batch === "councellor") {
          // console.log("22");
          
          const duration = moment.duration(start.diff(currentTime));
          const hours = duration.hours();
          const minutes = duration.minutes();
          const days = duration.days();

          let timeRemaining = "";
          if (days > 0) {
            timeRemaining = `${days} day(s)`;
          } else if (hours > 0) {
            timeRemaining = `${hours} hour(s)`;
          } else if (minutes > 0) {
            timeRemaining = `${minutes} minute(s)`;
          }

          setUpcomingCounselorMarker({
            message: `You have a meeting with a councellor in ${timeRemaining}.`,
            // start: start.toDate(),
            start: start.toLocaleString(),
          });
        }

        // if (
        //   start.isAfter(currentTime) &&
        //   (!nextClassTime || start.isBefore(nextClassTime))
        // ) {
        //   nextClassTime = start;
        //   nextClassBatch = batch;
        // }
        // const check = {
        //   id: index + 1,
        //   title: batch,
        //   start: start.toDate(),
        //   end: end.toDate(),
        //   url: joinUrl,
        // };
        // console.log("check", check);

        return {
          id: index + 1,
          title: batch,
          start: start.toDate(),
          end: end.toDate(),
          url: joinUrl,
        };
      });

      setEvents(updatedEvents);

      // if (!ongoingEventFound && nextClassTime) {
      //   const duration = moment.duration(nextClassTime.diff(currentTime));
      //   const days = Math.floor(duration.asDays());
      //   const hours = duration.hours();
      //   const minutes = duration.minutes();

      //   const timeLeftMessage = `Time left for next class (${nextClassBatch}): ${
      //     days > 0 ? days + " days " : ""
      //   }${hours > 0 ? hours + " hours " : ""}${minutes} minutes`;
      //   // setNextClass(timeLeftMessage);
      // }
    }
  }, [datas]);
// useEffect(() => {
//   console.log("events",events);
  

  
// }, [events])
// useEffect(() => {
//   console.log("UpcomingCounselorMarker",UpcomingCounselorMarker);
  

  
// }, [UpcomingCounselorMarker])
// console.log("UpcomingCounselorMarker || ongoingEvent",UpcomingCounselorMarker ,ongoingEvent);

  const saveBatch = async (e) => {
    // console.log(e);
    setSection(e);
    const dataToSend = {
      section: e,
    };
    try {
      const response = await axios.put(
        `https://api.studentrays.com/api/users/${user._id}`,
        dataToSend
      );
      console.log("Data sent successfully:", response.data);
      alert(
        `You selected Batch ${e} successfully! You can now move to perchase course.`
      );
      setBatch(e);
    } catch (err) {
      console.error("Batch selection failed:", err);
      if (err.response) {
        console.error("Server error:", err.response.status, err.response.data);
      } else if (err.request) {
        console.error("Network error:", err.request);
      } else {
        console.error("General error:", err.message);
      }
    }
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault(); // Prevent the form from refreshing the page

  //   const formData = new FormData(event.target);
  //   const formProps = Object.fromEntries(formData.entries());

  //   console.log("formprops",formProps); // Log the form data
  //   // const batch = `${data.course}${data.grade}${formProps.subject}${data.section}`;
  //   const batch =
  //     `${user.course}${user.grade}${formProps.subject}${user.section}`.replace(
  //       /\s+/g,
  //       ""
  //     );
  //   const lowerCaseBatch = batch.toLowerCase();
  //   console.log(lowerCaseBatch); // Outputs: "cbse8mata" // Outputs: "cbsc8bsci"

  //   try {
  //     const updatedBatch = [lowerCaseBatch];
  //     const newBatch = lowerCaseBatch;
  //     // if (formProps.subject == "sci") {
  //     //   setMoneypay(sciPay);
  //     //   // const pay = sciPay;
  //     // } else if (formProps.subject == "mat") {
  //     //   setMoneypay(matPay);
  //     //   // const pay = sciPay;
  //     // }
  //     setMoneypay(formProps.subject === "sci" ? sciPay : formProps.subject === "mat" ? sciPay : bothPay);

  //     const batchdata = {
  //       course: user.course,
  //       grade: user.grade,
  //       section: user.section,
  //       subject: formProps.subject,
  //       currentbatch: newBatch,
  //       cost: moneypay,
  //     };

  //     console.log("batchdata", batchdata);

  //     const batchResponse = await axios.post(
  //       `https://api.studentrays.com/api/batchs/${user._id}`,
  //       batchdata
  //     );
  //     console.log("batchResponse", batchResponse);

  //     // Send the updated data back to the server
  //     // const updateResponse = await axios.put(
  //     //   `https://api.studentrays.com/api/users/${user._id}`,
  //     //   {
  //     //     batch: updatedBatch,
  //     //   }
  //     // );
  //     const assignedData = {
  //       batch: newBatch,
  //       teacherAssigned: "", // Leave teacherAssigned empty
  //     };

  //     // Check if user.assignedid exists and is an array
  //     // const assignedIdArray = Array.isArray(user.assignedid) ? user.assignedid : [];

  //     // Append new data to assignedid array
  //     const updateResponse = await axios.put(
  //       `https://api.studentrays.com/api/users/${user._id}`,
  //       {
  //         assignedid: [...user.assignedid, assignedData],
  //       }
  //     );
  //     // if (formProps.subject === "sci") {
  //     //   setSubject("Science");
  //     // } else {
  //     //   setSubject("Maths");
  //     // }
  //     setSubject(formProps.subject === "sci" ? "Science" : formProps.subject === "mat" ? "Maths" : "Both");

  //     // setSubject(formProps.subject);
  //     console.log("updateResponse", updateResponse);
  //     // setAllbatch(updatedBatch);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the form from refreshing the page

    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData.entries());

    // console.log("formprops", formProps); // Log the form data

    // Create batch string
    const batch =
      `${user.course}${user.grade}${formProps.subject}${section}`.replace(
        /\s+/g,
        ""
      );
    let lowerCaseBatch = batch.toLowerCase();
    // console.log(lowerCaseBatch); // Outputs: "cbse8mata" or "cbsc8bsci"

    try {
      let assignedData = [];

      if (formProps.subject === "both") {
        // Handle "both" case
        const sciBatch = lowerCaseBatch.replace("both", "sci"); // Replace "both" with "sci"
        const matBatch = lowerCaseBatch.replace("both", "mat"); // Replace "both" with "mat"

        // Set payment for both (combined payment)
        setMoneypay(bothPay);

        // Add both batches to assignedData
        assignedData = [
          {
            batch: sciBatch,
            teacherAssigned: "", // Leave teacherAssigned empty
          },
          {
            batch: matBatch,
            teacherAssigned: "", // Leave teacherAssigned empty
          },
        ];

        lowerCaseBatch = `${sciBatch}, ${matBatch}`; // For logging purposes
      } else {
        // Handle single subject cases (sci or mat)
        setMoneypay(formProps.subject === "sci" ? sciPay : matPay);

        assignedData = [
          {
            batch: lowerCaseBatch,
            teacherAssigned: "", // Leave teacherAssigned empty
          },
        ];
      }

      // Prepare batch data for batch creation
      const batchdata = {
        course: user.course,
        grade: user.grade,
        section: user.section,
        subject: formProps.subject,
        currentbatch: lowerCaseBatch,
        cost: moneypay,
      };

      // console.log("batchdata", batchdata);

      // Send batch data to create batch
      const batchResponse = await axios.post(
        `https://api.studentrays.com/api/batchs/${user._id}`,
        batchdata
      );
      // console.log("batchResponse", batchResponse);

      // Send updated data to the server (add new batches to assignedid array)
      const updateResponse = await axios.put(
        `https://api.studentrays.com/api/users/${user._id}`,
        {
          assignedid: [...user.assignedid, ...assignedData], // Append new batch data
        }
      );

      // console.log("updateResponse", updateResponse);

      // Set the subject for UI (both or individual)
      setSubject(
        formProps.subject === "sci"
          ? "Science"
          : formProps.subject === "mat"
          ? "Maths"
          : "Both"
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <DashNavbar />
      <div className="container">
        {user && (
          <DashboardUserData
            name={user.name}
            course={user.course}
            grade={user.grade}
            phone={user.phone}
            email={user.email}
            state={user.state}
          />
        )}
        {(UpcomingCounselorMarker || ongoingEvent) && (
          <div className="joinClass">
            {UpcomingCounselorMarker && (
              <div className="joinClassUpper">
                <div className="joinClassInner">
                  {UpcomingCounselorMarker.message} <br /> Meeting start at:
                  {UpcomingCounselorMarker.start}
                </div>
              </div>
            )}
            {ongoingEvent && (
              <div>
                <p>Join Meeting with {ongoingEvent.title}</p>
                <a
                  href={ongoingEvent.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn btn-primary">Join</button>
                </a>
              </div>
            )}
          </div>
        )}
        <div className="heading">Choose your Batch</div>
        <div className="batchSelection">
          <div className="batchTimeBox">
            <span className="batchOption">Batch A</span>
            <span>6:00 - 7:00 PM</span>
            <button className="batchButton" onClick={() => saveBatch("A")}>
              choose
            </button>
          </div>
          <div className="batchTimeBox">
            <span className="batchOption">Batch B</span>
            <span>7:00 - 8:00 PM</span>
            <button className="batchButton" onClick={() => saveBatch("B")}>
              choose
            </button>
          </div>
          <div className="batchTimeBox">
            <span className="batchOption">Batch C</span>
            <span>8:00 - 9:00 PM</span>
            <button className="batchButton" onClick={() => saveBatch("C")}>
              choose
            </button>
          </div>
        </div>
        <h2>Select Subject</h2>

        <Form className="payForm" onSubmit={handleSubmit}>
          {["radio"].map((type) => (
            <div key={`inline-${type}`} className="selectSubject">
              <div className="paysubjectSelection">
                <Form.Check
                  inline
                  label="Science"
                  name="subject"
                  type={type}
                  id={`inline-${type}-1`}
                  value="sci"
                  style={{
                    "fontWeight": "600",
                    "display": "flex",
                    "gap": "20px",
                    "align-items": "center",
                    "fontSize": "1.5rem",
                  }}
                />
                <span>
                  Payable amount: Rs <b>{sciPay}</b>
                </span>
              </div>
              <div className="paysubjectSelection">
                <Form.Check
                  inline
                  label="Maths"
                  name="subject"
                  type={type}
                  id={`inline-${type}-2`}
                  value="mat"
                  style={{
                    "fontWeight": "600",
                    "display": "flex",
                    "gap": "20px",
                    "align-items": "center",
                    "fontSize": "1.5rem",
                  }}
                />
                <span>
                  Payable amount: Rs <b>{matPay}</b>
                </span>
              </div>
              <div className="paysubjectSelection">
                <Form.Check
                  inline
                  label="Both"
                  name="subject"
                  type={type}
                  id={`inline-${type}-2`}
                  value="both"
                  // disabled
                  style={{
                    "fontWeight": "600",
                    "display": "flex",
                    "gap": "20px",
                    "align-items": "center",
                    "fontSize": "1.5rem",
                  }}
                />
                <span>
                  Payable amount: Rs <b>{bothPay}</b>
                </span>
                <span></span>
              </div>
            </div>
          ))}
          <button className="batchButton" type="submit">
            Submit
          </button>
        </Form>

        {batch && <p>your Selected Section is {batch}</p>}
        {subject && <p>your Selected Subject is {subject}</p>}
        {batch && subject ? (
          <OrderButton
            username={user.username}
            emaildata={user.email}
            amount={moneypay}
            userdata={user}
          />
        ) : (
          <p>Please Select Section and Subject to see payment Button</p>
        )}

        {/* <EmailButton /> */}
        {/* <div className="videoHead">Videos that may interest you</div>
        <div className="playerAreea">
          <VideoPages />
          <VideoPages />
          <VideoPages />
        </div>*/}
      </div>
      <Footer />
    </>
  );
};

export default Paydashboard;
