import React from "react";
import useFetch from "../../../hooks/useFetch";
import { Link } from "react-router-dom";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import "./studybord.css";
const Studybord = (props) => {
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/subtopics/${props.id}`);
  // console.log("propdata", data);
  // console.log("ieed", props.id);
  // console.log("miniTopicid", props.miniTopicid);
  return (
    <div className="allData">
      <Link to={`/dashboard/topic/study/${props.id}/${props.miniTopicid}`}>
        {props.show ? (
          <AiFillUnlock className="iconfa" />
        ) : (
          <AiFillLock className="iconfa" />
        )}
        {/* {data.lock ? (
          <AiFillLock className="iconfa" />
        ) : (
          <AiFillUnlock className="iconfa" />
        )} */}
      </Link>
      <div className="newtopicName">
        {data.topic}
      </div>
    </div>
  );
};

export default Studybord;
