
// Grade 6 Science

export const gr6sci = {
  data: "CBSE Content for Class 6 Science",
  list:[
    {abelableContent: "Chapter 1 : Food Where Does It Come From"},
    {abelableContent: "Chapter 2 : Components of Food"},
    {abelableContent: "Chapter 3 : Fibre to Fabric"},
    {abelableContent: "Chapter 4 : Sorting Materials Into Groups"},
    {abelableContent: "Chapter 5 : Separation of Substances"},
    {abelableContent: "Chapter 6 : Changes Around Us"},
    {abelableContent: "Chapter 7 : Getting to Know Plants"},
    {abelableContent: "Chapter 8 : Body Movements"},
    {abelableContent: "Chapter 9 : The Living Organisms and Their Surroundings"},
    {abelableContent: "Chapter 10 : Motion and Measurement of Distances"},
    {abelableContent: "Chapter 11 : Light Shadows and Reflection"},
    {abelableContent: "Chapter 12 : Electricity and Circuits"},
    {abelableContent: "Chapter 13 : Fun with Magnets"},
    {abelableContent: "Chapter 14 : Water"},
    {abelableContent: "Chapter 15 : Air Around Us"},
    {abelableContent: "Chapter 16 : Garbage In Garbage Out"}
  ],
  questions: [
    {
      question: "Which part of the plant is mainly responsible for photosynthesis?",
      options: ["Root", "Stem", "Leaf", "Flower"],
      correctAnswer: "Leaf",
    },
    {
      question: "Which of the following is a source of carbohydrates?",
      options: ["Milk", "Rice", "Meat", "Egg"],
      correctAnswer: "Rice",
    },
    {
      question: "Which of the following nutrients helps in building and repairing tissues?",
      options: ["Carbohydrates", "Proteins", "Fats", "Vitamins"],
      correctAnswer: "Proteins",
    },
    {
      question: "Which vitamin is essential for good vision?",
      options: ["Vitamin A", "Vitamin B", "Vitamin C", "Vitamin D"],
      correctAnswer: "Vitamin A",
    },
    {
      question: "Which of the following materials is magnetic?",
      options: ["Wood", "Glass", "Iron", "Plastic"],
      correctAnswer: "Iron",
    },
    {
      question: "Materials that can be stretched into thin wires are called:",
      options: ["Brittle", "Ductile", "Malleable", "Elastic"],
      correctAnswer: "Ductile",
    },
    {
      question: "What method is used to separate a mixture of salt and water?",
      options: ["Filtration", "Evaporation", "Decantation", "Handpicking"],
      correctAnswer: "Evaporation",
    },
    {
      question: "Which method is used to separate iron filings from a mixture of sand and iron?",
      options: ["Filtration", "Magnetism", "Sieving", "Sedimentation"],
      correctAnswer: "Magnetism",
    },
    {
      question: "What type of change is freezing of water?",
      options: ["Physical change", "Chemical change", "Irreversible change", "Both A and B"],
      correctAnswer: "Physical change",
    },
    {
      question: "Burning of wood is an example of:",
      options: ["Physical change", "Chemical change", "Reversible change", "Irreversible change"],
      correctAnswer: "Chemical change",
    },
    {
      question: "Which part of the plant absorbs water and nutrients from the soil?",
      options: ["Stem", "Leaf", "Root", "Flower"],
      correctAnswer: "Root",
    },
    {
      question: "The process by which plants make their own food is called:",
      options: ["Digestion", "Photosynthesis", "Respiration", "Germination"],
      correctAnswer: "Photosynthesis",
    },
    {
      question: "The bone that protects the brain is known as:",
      options: ["Sternum", "Pelvis", "Skull", "Ribcage"],
      correctAnswer: "Skull",
    },
    {
      question: "Which type of joint allows movement in all directions?",
      options: ["Hinge joint", "Ball and socket joint", "Pivot joint", "Fixed joint"],
      correctAnswer: "Ball and socket joint",
    },
    {
      question: "Which of the following is not a characteristic of living organisms?",
      options: ["Growth", "Reproduction", "Movement", "Respiration"],
      correctAnswer: "Movement",
    },
    {
      question: "Which of the following is an example of a non-living thing?",
      options: ["Tree", "Water", "Bird", "Human"],
      correctAnswer: "Water",
    },
    {
      question: "The unit used to measure length in the metric system is:",
      options: ["Liter", "Gram", "Meter", "Joule"],
      correctAnswer: "Meter",
    },
    {
      question: "Which instrument is used to measure length?",
      options: ["Thermometer", "Stopwatch", "Ruler", "Barometer"],
      correctAnswer: "Ruler",
    },
    {
      question: "The formation of a shadow occurs due to:",
      options: ["Reflection of light", "Refraction of light", "Absorption of light", "Blocking of light"],
      correctAnswer: "Blocking of light",
    },
    {
      question: "The image formed by a plane mirror is:",
      options: ["Real and inverted", "Real and upright", "Virtual and upright", "Virtual and inverted"],
      correctAnswer: "Virtual and upright",
    },
    {
      question: "A circuit with a single path for current flow is called a:",
      options: ["Series circuit", "Parallel circuit", "Complex circuit", "Open circuit"],
      correctAnswer: "Series circuit",
    },
    {
      question: "Which of the following is a good conductor of electricity?",
      options: ["Wood", "Plastic", "Copper", "Glass"],
      correctAnswer: "Copper",
    },
    {
      question: "The process by which water changes into water vapor is called:",
      options: ["Condensation", "Precipitation", "Evaporation", "Transpiration"],
      correctAnswer: "Evaporation",
    },
    {
      question: "Which of the following is not a method of water conservation?",
      options: ["Using a bucket for washing", "Fixing leaking taps", "Watering plants during the hottest part of the day", "Collecting rainwater"],
      correctAnswer: "Watering plants during the hottest part of the day",
    },
  ],  
};
// Grade 6 Maths
export const gr6mat = {
  data: "CBSE Content for Class 6 Maths",
  list:[
    {abelableContent: "Chapter : 1 Knowing Our Numbers"},
    {abelableContent: "Chapter : 2 Whole Numbers"},
    {abelableContent: "Chapter : 3 Playing With Numbers"},
    {abelableContent: "Chapter : 4 Basic Geometrical Ideas"},
    {abelableContent: "Chapter : 5 Understanding Elementary Shape"},
    {abelableContent: "Chapter : 6 Integers"},
    {abelableContent: "Chapter : 7 Fractions"},
    {abelableContent: "Chapter : 8 Decimals"},
    {abelableContent: "Chapter : 9 Data Handling"},
    {abelableContent: "Chapter : 10 Mensuration"},
    {abelableContent: "Chapter : 10 Mensuration"},
    {abelableContent: "Chapter : 11 Algebra"},
    {abelableContent: "Chapter : 12 Ratio and Proportion"},
    {abelableContent: "Chapter : 13 Symmetry"},
    {abelableContent: "Chapter : 14 Practical Geometry"},
  ],
  questions: [
    {
      "question": "What is the place value of 7 in the number 56,738?",
      "options": ["7", "70", "700", "7000"],
      "correctAnswer": "7000"
    },
    {
      "question": "What is the sum of 204 and 315?",
      "options": ["519", "529", "509", "614"],
      "correctAnswer": "519"
    },
    {
      "question": "Which of the following is the largest whole number?",
      "options": ["999", "1000", "1001", "1002"],
      "correctAnswer": "1002"
    },
    {
      "question": "Subtract 125 from 900. What is the result?",
      "options": ["775", "825", "875", "925"],
      "correctAnswer": "775"
    },
    {
      "question": "What is the smallest prime number?",
      "options": ["1", "2", "3", "4"],
      "correctAnswer": "2"
    },
    {
      "question": "Which of the following numbers is divisible by 5?",
      "options": ["123", "126", "130", "132"],
      "correctAnswer": "130"
    },
    {
      "question": "How many sides does a pentagon have?",
      "options": ["4", "5", "6", "7"],
      "correctAnswer": "5"
    },
    {
      "question": "A straight angle measures:",
      "options": ["90°", "180°", "360°", "270°"],
      "correctAnswer": "180°"
    },
    {
      "question": "The number of vertices in a cube is:",
      "options": ["6", "8", "12", "4"],
      "correctAnswer": "8"
    },
    {
      "question": "Which of the following shapes has no vertices?",
      "options": ["Triangle", "Square", "Circle", "Rectangle"],
      "correctAnswer": "Circle"
    },
    {
      "question": "What is 14 1/4 + 14 1/4?",
      "options": ["12 1/2", "14 1/4", "24 2/4", "28 2/8"],
      "correctAnswer": "12 1/2"
    },
    {
      "question": "Which of the following fractions is equivalent to 36 3/6?",
      "options": ["12 1/2", "13 1/3", "23 2/3", "35 3/5"],
      "correctAnswer": "12 1/2"
    },
    {
      "question": "What is 0.75 expressed as a fraction?",
      "options": ["3/4", "7/10", "75/100", "15/20"],
      "correctAnswer": "3/4"
    },
    {
      "question": "Add 2.5 and 3.7.",
      "options": ["5.2", "6.2", "6.0", "5.5"],
      "correctAnswer": "6.2"
    },
    {
      "question": "The mode of the following data set {4, 5, 7, 5, 8, 5} is:",
      "options": ["4", "5", "7", "8"],
      "correctAnswer": "5"
    },
    {
      "question": "What is the median of the data set {3, 7, 5, 1, 9} when arranged in ascending order?",
      "options": ["3", "5", "7", "9"],
      "correctAnswer": "5"
    },
    {
      "question": "If x = 5, what is the value of 2x + 3?",
      "options": ["10", "13", "16", "23"],
      "correctAnswer": "13"
    },
    {
      "question": "Simplify the expression 4a − 2a + 7.",
      "options": ["2a + 7", "6a + 7", "2a − 7", "4a + 7"],
      "correctAnswer": "2a + 7"
    },
    {
      "question": "What is the perimeter of a rectangle with length 8 cm and width 5 cm?",
      "options": ["26 cm", "40 cm", "30 cm", "13 cm"],
      "correctAnswer": "26 cm"
    },
    {
      "question": "The area of a square with side length 7 cm is:",
      "options": ["49 cm²", "14 cm²", "21 cm²", "28 cm²"],
      "correctAnswer": "49 cm²"
    },
    {
      "question": "A shape is said to have symmetry if:",
      "options": ["It can be divided into two identical parts", "It has only one side", "It has no lines or angles", "It is made of straight lines only"],
      "correctAnswer": "It can be divided into two identical parts"
    },
    {
      "question": "The letter 'A' has:",
      "options": ["No line of symmetry", "One line of symmetry", "Two lines of symmetry", "Four lines of symmetry"],
      "correctAnswer": "One line of symmetry"
    },
    {
      "question": "What is the next number in the sequence 2, 4, 6, 8, ___?",
      "options": ["10", "12", "14", "16"],
      "correctAnswer": "10"
    },
    {
      "question": "In the pattern 1, 3, 6, 10, 15, ___, what comes next?",
      "options": ["21", "22", "20", "25"],
      "correctAnswer": "21"
    }
  ],  
};

// Grade 7 Science
export const gr7sci = {
  data: "CBSE Content for Class 7 Science",
  list:[
    {abelableContent: "Chapter 1 Nutrition in Plants"},
    {abelableContent: "Chapter 2 Nutrition in Animals"},
    {abelableContent: "Chapter 3 Fibre to Fabric"},
    {abelableContent: "Chapter 4 Heat"},
    {abelableContent: "Chapter 5 Acids, Bases and Salts"},
    {abelableContent: "Chapter 6 Physical and Chemical Changes"},
    {abelableContent: "Chapter 7 Weather, Climate and Adaptations of Animals to Climate"},
    {abelableContent: "Chapter 8 Winds, Storms and Cyclones"},
    {abelableContent: "Chapter 9 Soil"},
    {abelableContent: "Chapter 10 Respiration in Organisms"},
    {abelableContent: "Chapter 11 Transportation in Animals and Plants"},
    {abelableContent: "Chapter 12 Reproduction in Plants"},
    {abelableContent: "Chapter 13 Motion and Time"},
    {abelableContent: "Chapter 14 Electric Current and Its Effects"},
    {abelableContent: "Chapter 15 Light"},
    {abelableContent: "Chapter 16 Water: A Precious Resource"},
    {abelableContent: "Chapter 17 Forests: Our Lifeline"},
    {abelableContent: "Chapter 18 Wastewater Story"}
  ],
   questions:[
    {
      question: "Which of the following is not a part of the process of photosynthesis?",
      options: ["Absorption of sunlight", "Conversion of carbon dioxide into oxygen", "Formation of glucose", "Absorption of water from the soil"],
      correctAnswer: "Conversion of carbon dioxide into oxygen",
    },
    {
      question: "What is the green pigment present in plants that helps in photosynthesis?",
      options: ["Carotene", "Chlorophyll", "Xanthophyll", "Anthocyanin"],
      correctAnswer: "Chlorophyll",
    },
    {
      question: "Which organ in the human digestive system is responsible for the absorption of nutrients?",
      options: ["Stomach", "Small intestine", "Large intestine", "Esophagus"],
      correctAnswer: "Small intestine",
    },
    {
      question: "The process of breaking down food into simpler substances in the human body is called:",
      options: ["Digestion", "Respiration", "Circulation", "Excretion"],
      correctAnswer: "Digestion",
    },
    {
      question: "Which of the following is a natural fiber used in making clothes?",
      options: ["Nylon", "Polyester", "Cotton", "Acrylic"],
      correctAnswer: "Cotton",
    },
    {
      question: "Wool is obtained from:",
      options: ["Plants", "Animals", "Synthetic materials", "Minerals"],
      correctAnswer: "Animals",
    },
    {
      question: "Which of the following is a good conductor of heat?",
      options: ["Wood", "Plastic", "Glass", "Metal"],
      correctAnswer: "Metal",
    },
    {
      question: "The transfer of heat through a fluid (liquid or gas) is called:",
      options: ["Conduction", "Convection", "Radiation", "Reflection"],
      correctAnswer: "Convection",
    },
    {
      question: "Which of the following is an example of a base?",
      options: ["Vinegar", "Lemon juice", "Baking soda", "Hydrochloric acid"],
      correctAnswer: "Baking soda",
    },
    {
      question: "The pH scale measures the:",
      options: ["Temperature of a substance", "Concentration of hydrogen ions in a solution", "Amount of dissolved oxygen in a solution", "Concentration of salt in a solution"],
      correctAnswer: "Concentration of hydrogen ions in a solution",
    },
    {
      question: "Which of the following is an example of a physical change?",
      options: ["Burning of wood", "Dissolving sugar in water", "Rusting of iron", "Cooking an egg"],
      correctAnswer: "Dissolving sugar in water",
    },
    {
      question: "When iron reacts with oxygen and moisture, it forms:",
      options: ["Salt", "Sugar", "Rust", "Acid"],
      correctAnswer: "Rust",
    },
    {
      question: "The long-term pattern of weather in a particular area is known as:",
      options: ["Weather", "Climate", "Meteorology", "Forecast"],
      correctAnswer: "Climate",
    },
    {
      question: "Which of the following animals is adapted to live in a desert environment?",
      options: ["Penguin", "Polar bear", "Camel", "Dolphin"],
      correctAnswer: "Camel",
    },
    {
      question: "Which type of soil is best for growing crops?",
      options: ["Sandy soil", "Clay soil", "Loamy soil", "Saline soil"],
      correctAnswer: "Loamy soil",
    },
    {
      question: "The top layer of soil, rich in humus and minerals, is called:",
      options: ["Subsoil", "Bedrock", "Topsoil", "Soil horizon"],
      correctAnswer: "Topsoil",
    },
    {
      question: "The process of obtaining energy from food in the presence of oxygen is called:",
      options: ["Photosynthesis", "Respiration", "Digestion", "Circulation"],
      correctAnswer: "Respiration",
    },
    {
      question: "The exchange of gases between the blood and the air in the lungs occurs in the:",
      options: ["Trachea", "Bronchi", "Alveoli", "Larynx"],
      correctAnswer: "Alveoli",
    },
    {
      question: "The tissue in plants that transports water and minerals from the roots to the leaves is called:",
      options: ["Phloem", "Xylem", "Epidermis", "Cortex"],
      correctAnswer: "Xylem",
    },
    {
      question: "The blood vessel that carries oxygenated blood from the heart to the body is:",
      options: ["Vein", "Artery", "Capillary", "Valve"],
      correctAnswer: "Artery",
    },
    {
      question: "The transfer of pollen from the male part of a flower to the female part is known as:",
      options: ["Fertilization", "Pollination", "Germination", "Seed dispersal"],
      correctAnswer: "Pollination",
    },
    {
      question: "Which part of the flower develops into a fruit?",
      options: ["Stigma", "Style", "Ovary", "Anther"],
      correctAnswer: "Ovary",
    },
    {
      question: "The speed of an object is defined as:",
      options: ["Distance traveled per unit time", "Distance traveled", "Time taken", "Acceleration"],
      correctAnswer: "Distance traveled per unit time",
    },
    {
      question: "If a car travels 60 km in 1 hour, what is its speed?",
      options: ["60 km/h", "120 km/h", "30 km/h", "45 km/h"],
      correctAnswer: "60 km/h",
    },
  ],  
};
// Grade 7 Maths
export const gr7mat = {
  data: "CBSE Content for Class 7 Maths",
  list:[
    {abelableContent: "Chapter 1 Integers"},
    {abelableContent: "Chapter 2 Fractions and Decimals"},
    {abelableContent: "Chapter 3 Data Handling"},
    {abelableContent: "Chapter 4 Simple Equations"},
    {abelableContent: "Chapter 5 Lines and Angles"},
    {abelableContent: "Chapter 6 The Triangles and its Properties"},
    {abelableContent: "Chapter 7 Congruence of Triangles"},
    {abelableContent: "Chapter 8 Comparing Quantities"},
    {abelableContent: "Chapter 9 Rational Numbers"},
    {abelableContent: "Chapter 10 Practical Geometry"},
    {abelableContent: "Chapter 11 Perimeter and Area"},
    {abelableContent: "Chapter 12 Algebraic Expressions"},
    {abelableContent: "Chapter 13 Exponents and Powers"},
    {abelableContent: "Chapter 14 Symmetry"}
  ],
  questions: [
    {
      "question": "What is the result of (-5) + 8?",
      "options": ["3", "13", "-13", "-3"],
      "correctAnswer": "3"
    },
    {
      "question": "Which of the following integers is the smallest?",
      "options": ["-7", "0", "5", "-3"],
      "correctAnswer": "-7"
    },
    {
      "question": "Which of the following fractions is equivalent to 3/4?",
      "options": ["6/8", "9/12", "12/16", "All of the above"],
      "correctAnswer": "All of the above"
    },
    {
      "question": "Convert 0.75 into a fraction.",
      "options": ["3/4", "7/10", "6/8", "75/100"],
      "correctAnswer": "3/4"
    },
    {
      "question": "If the ratio of boys to girls in a class is 3:4 and there are 28 girls, how many boys are there?",
      "options": ["21", "24", "12", "18"],
      "correctAnswer": "21"
    },
    {
      "question": "What is the value of x in the proportion 5/7 = x/14?",
      "options": ["10", "7", "5", "4"],
      "correctAnswer": "10"
    },
    {
      "question": "Simplify the expression 3x + 5 - 2x + 8.",
      "options": ["x + 13", "x + 3", "5x + 13", "x + 8"],
      "correctAnswer": "x + 13"
    },
    {
      "question": "What is the value of 4a - 2 when a = 3?",
      "options": ["10", "14", "8", "12"],
      "correctAnswer": "10"
    },
    {
      "question": "Solve for x: 2x - 4 = 10.",
      "options": ["6", "7", "5", "8"],
      "correctAnswer": "7"
    },
    {
      "question": "What is the value of x in the equation x + 9 = 15?",
      "options": ["6", "7", "5", "4"],
      "correctAnswer": "6"
    },
    {
      "question": "If two lines intersect and form a right angle, they are called:",
      "options": ["Parallel lines", "Perpendicular lines", "Skew lines", "Concurrent lines"],
      "correctAnswer": "Perpendicular lines"
    },
    {
      "question": "The sum of the interior angles of a triangle is:",
      "options": ["90°", "180°", "360°", "270°"],
      "correctAnswer": "180°"
    },
    {
      "question": "In an equilateral triangle, each angle measures:",
      "options": ["60°", "90°", "45°", "120°"],
      "correctAnswer": "60°"
    },
    {
      "question": "What is the longest side of a right-angled triangle called?",
      "options": ["Altitude", "Hypotenuse", "Base", "Perpendicular"],
      "correctAnswer": "Hypotenuse"
    },
    {
      "question": "Two triangles are said to be congruent if:",
      "options": ["All their corresponding angles and sides are equal", "Only their corresponding angles are equal", "Only their corresponding sides are equal", "None of the above"],
      "correctAnswer": "All their corresponding angles and sides are equal"
    },
    {
      "question": "The criteria for the congruence of triangles is:",
      "options": ["SAS (Side-Angle-Side)", "SSS (Side-Side-Side)", "ASA (Angle-Side-Angle)", "All of the above"],
      "correctAnswer": "All of the above"
    },
    {
      "question": "What is the perimeter of a rectangle with length 6 cm and width 4 cm?",
      "options": ["20 cm", "24 cm", "30 cm", "12 cm"],
      "correctAnswer": "20 cm"
    },
    {
      "question": "The area of a square with side length 5 cm is:",
      "options": ["25 cm²", "20 cm²", "15 cm²", "10 cm²"],
      "correctAnswer": "25 cm²"
    },
    {
      "question": "The volume of a cube with edge length 3 cm is:",
      "options": ["9 cm³", "27 cm³", "18 cm³", "12 cm³"],
      "correctAnswer": "27 cm³"
    },
    {
      "question": "The surface area of a cube with side length 4 cm is:",
      "options": ["64 cm²", "48 cm²", "36 cm²", "16 cm²"],
      "correctAnswer": "96 cm²"
    },
    {
      "question": "What is the mode of the data set {3, 7, 5, 7, 8}?",
      "options": ["3", "7", "5", "8"],
      "correctAnswer": "7"
    },
    {
      "question": "If the average of five numbers is 10, what is their total sum?",
      "options": ["50", "40", "30", "20"],
      "correctAnswer": "50"
    }
  ],  
};
// Grade 8 Science

export const gr8sci = {
  data: "CBSE Content for Class 8 Science",
  list:[
    {abelableContent: "Chapter 1 Crop Production and Management"},
    {abelableContent: "Chapter 2 Microorganisms: Friend and Foe"},
    {abelableContent: "Chapter 3 Synthetic Fibres and Plastics"},
    {abelableContent: "Chapter 4 Materials: Metals and Non-Metals"},
    {abelableContent: "Chapter 5 Coal and Petroleum"},
    {abelableContent: "Chapter 6 Combustion and Flame"},
    {abelableContent: "Chapter 7 Conservation of Plants and Animals"},
    {abelableContent: "Chapter 8 Cell Structure and Functions"},
    {abelableContent: "Chapter 9 Reproduction in Animals"},
    {abelableContent: "Chapter 10 Reaching the Age of Adolescence"},
    {abelableContent: "Chapter 11 Force and Pressure"},
    {abelableContent: "Chapter 12 Friction"},
    {abelableContent: "Chapter 13 Sound"},
    {abelableContent: "Chapter 14 Chemical Effects of Electric Current"},
    {abelableContent: "Chapter 15 Some Natural Phenomena"},
    {abelableContent: "Chapter 16 Light"},
    {abelableContent: "Chapter 17 Stars and the Solar System"},
    {abelableContent: "Chapter 18 Pollution of Air and Water"}
  ],
  questions: [
    {
      question: "Which of the following is a method of irrigation?",
      options: ["Photosynthesis", "Plowing", "Drip irrigation", "Germination"],
      correctAnswer: "Drip irrigation"
    },
    {
      question: "What is the primary purpose of adding fertilizers to soil?",
      options: ["To increase soil temperature", "To enhance soil structure", "To provide essential nutrients to plants", "To remove weeds"],
      correctAnswer: "To provide essential nutrients to plants"
    },
    {
      question: "Which microorganism is used in the production of bread?",
      options: ["Bacteria", "Virus", "Yeast", "Algae"],
      correctAnswer: "Yeast"
    },
    {
      question: "What is the role of microorganisms in nitrogen fixation?",
      options: ["They decompose organic matter", "They convert nitrogen gas into ammonia", "They cause diseases in plants", "They produce oxygen"],
      correctAnswer: "They convert nitrogen gas into ammonia"
    },
    {
      question: "Which of the following is a natural fiber?",
      options: ["Nylon", "Polyester", "Cotton", "Acrylic"],
      correctAnswer: "Cotton"
    },
    {
      question: "Plastics that can be molded into different shapes when heated and retain their shape on cooling are called:",
      options: ["Thermoplastics", "Thermosetting plastics", "Biodegradable plastics", "Synthetic plastics"],
      correctAnswer: "Thermoplastics"
    },
    {
      question: "Which of the following metals is used to make electric wires due to its high conductivity?",
      options: ["Iron", "Gold", "Copper", "Zinc"],
      correctAnswer: "Copper"
    },
    {
      question: "The non-metal that is essential for respiration is:",
      options: ["Helium", "Hydrogen", "Oxygen", "Nitrogen"],
      correctAnswer: "Oxygen"
    },
    {
      question: "The process of burning a substance in the presence of oxygen is known as:",
      options: ["Respiration", "Combustion", "Photosynthesis", "Sublimation"],
      correctAnswer: "Combustion"
    },
    {
      question: "What type of flame is produced when there is complete combustion of a hydrocarbon?",
      options: ["Yellow flame", "Blue flame", "Red flame", "Green flame"],
      correctAnswer: "Blue flame"
    },
    {
      question: "Which of the following is an endangered species in India?",
      options: ["Bengal Tiger", "House Sparrow", "Common Crow", "Pigeon"],
      correctAnswer: "Bengal Tiger"
    },
    {
      question: "What is the primary cause of deforestation?",
      options: ["Overgrazing", "Industrialization", "Urbanization", "All of the above"],
      correctAnswer: "All of the above"
    },
    {
      question: "The term for the changes that occur in the human body during adolescence is:",
      options: ["Puberty", "Menstruation", "Fertilization", "Maturation"],
      correctAnswer: "Puberty"
    },
    {
      question: "Which gland is responsible for the release of hormones during adolescence?",
      options: ["Thyroid gland", "Pituitary gland", "Adrenal gland", "Pancreas"],
      correctAnswer: "Pituitary gland"
    },
    {
      question: "The SI unit of force is:",
      options: ["Newton", "Pascal", "Joule", "Watt"],
      correctAnswer: "Newton"
    },
    {
      question: "Pressure is defined as force per:",
      options: ["Area", "Volume", "Mass", "Length"],
      correctAnswer: "Area"
    },
    {
      question: "Friction is a force that:",
      options: ["Helps objects move smoothly", "Opposes the relative motion of surfaces in contact", "Increases the speed of objects", "Is always beneficial"],
      correctAnswer: "Opposes the relative motion of surfaces in contact"
    },
    {
      question: "Which of the following factors does not affect the frictional force between two surfaces?",
      options: ["Nature of the surfaces", "Area of contact", "Mass of the objects", "Speed of the moving object"],
      correctAnswer: "Speed of the moving object"
    },
    {
      question: "The speed of sound is greatest in which medium?",
      options: ["Solid", "Liquid", "Gas", "Vacuum"],
      correctAnswer: "Solid"
    },
    {
      question: "The unit of frequency is:",
      options: ["Meter", "Hertz", "Decibel", "Watt"],
      correctAnswer: "Hertz"
    },
    {
      question: "Electrolysis is the process of:",
      options: ["Breaking down substances using heat", "Breaking down substances using electricity", "Mixing substances", "Combining substances"],
      correctAnswer: "Breaking down substances using electricity"
    },
    {
      question: "Which of the following is an electrolyte?",
      options: ["Sugar solution", "Oil", "Distilled water", "Salt solution"],
      correctAnswer: "Salt solution"
    },
    {
      question: "What causes the occurrence of a rainbow?",
      options: ["Reflection of light", "Refraction and dispersion of light", "Diffraction of light", "Absorption of light"],
      correctAnswer: "Refraction and dispersion of light"
    },
    {
      question: "The natural phenomenon that causes the sky to appear blue is:",
      options: ["Scattering of light", "Reflection of light", "Refraction of light", "Dispersion of light"],
      correctAnswer: "Scattering of light"
    },
    {
      question: "Which planet is known as the 'Red Planet'?",
      options: ["Earth", "Mars", "Venus", "Jupiter"],
      correctAnswer: "Mars"
    },
    {
      question: "The largest planet in our solar system is:",
      options: ["Earth", "Saturn", "Jupiter", "Uranus"],
      correctAnswer: "Jupiter"
    }
  ],  
};
// Grade 8  Maths
	
export const gr8mat = {
  data: "CBSE Content for Class 8 Maths",
  list:[
    {abelableContent: "Chapter 1 Rational Numbers"},
    {abelableContent: "Chapter 2 Linear Equations in One Variable"},
    {abelableContent: "Chapter 3 Understanding Quadrilaterals"},
    {abelableContent: "Chapter 4 Practical Geometry"},
    {abelableContent: "Chapter 5 Data Handling"},
    {abelableContent: "Chapter 6 Squares and Square Roots"},
    {abelableContent: "Chapter 7 Cubes and Cube Roots"},
    {abelableContent: "Chapter 8 Comparing Quantities"},
    {abelableContent: "Chapter 9 Algebraic Expressions and Identities"},
    {abelableContent: "Chapter 10 Visualising Solid Shapes"},
    {abelableContent: "Chapter 11 Mensuration"},
    {abelableContent: "Chapter 12 Exponents and Powers"},
    {abelableContent: "Chapter 13 Direct and Indirect proportions"},
    {abelableContent: "Chapter 14 Factorisation"},
    {abelableContent: "Chapter 15 Introduction to Graphs"},
    {abelableContent: "Chapter 16 Playing with Numbers"}
  ],
  questions: [
    {
      question: "Which of the following is not a rational number?",
      options: ["34 3/4", "-2", "2√2", "0.75"],
      correctAnswer: "2√2"
    },
    {
      question: "What is the sum of 23 2/3 and 56 5/6?",
      options: ["76 7/6", "32 3/2", "16 1/6", "49 4/9"],
      correctAnswer: "76 7/6"
    },
    {
      question: "Solve the system of equations: 2x + 3y = 12 and x - y = 2. What are the values of x and y?",
      options: ["x=4, y=2", "x=5, y=1", "x=3, y=3", "x=2, y=4"],
      correctAnswer: "x=5, y=1"
    },
    {
      question: "What is the graphical representation of the equations x = 3 and y = 4?",
      options: ["Two intersecting lines", "Two parallel lines", "Two perpendicular lines", "One line passing through the origin"],
      correctAnswer: "Two parallel lines"
    },
    {
      question: "The sum of the interior angles of a quadrilateral is:",
      options: ["180°", "360°", "90°", "270°"],
      correctAnswer: "360°"
    },
    {
      question: "In a parallelogram, opposite angles are:",
      options: ["Complementary", "Supplementary", "Equal", "None of these"],
      correctAnswer: "Equal"
    },
    {
      question: "What is the median of the data set {4, 8, 6, 5, 7}?",
      options: ["4", "5", "6", "7"],
      correctAnswer: "6"
    },
    {
      question: "The mode of the data set {10, 12, 10, 15, 18, 12, 12} is:",
      options: ["10", "12", "15", "18"],
      correctAnswer: "12"
    },
    {
      question: "What is the value of the expression 3x - 4 when x = 5?",
      options: ["11", "15", "7", "8"],
      correctAnswer: "11"
    },
    {
      question: "Simplify the expression 2(a + 3b) - 4(a - b):",
      options: ["2a + 6b - 4a + 4b", "2a + 6b - 4a - 4b", "2a - 4a + 6b + 4b", "6b - 4b"],
      correctAnswer: "2a + 6b - 4a - 4b"
    },
    {
      question: "The square root of 144 is:",
      options: ["12", "14", "16", "18"],
      correctAnswer: "12"
    },
    {
      question: "Which of the following numbers is a perfect square?",
      options: ["35", "50", "64", "72"],
      correctAnswer: "64"
    },
    {
      question: "The cube of 3 is:",
      options: ["9", "27", "36", "81"],
      correctAnswer: "27"
    },
    {
      question: "The cube root of 125 is:",
      options: ["4", "5", "6", "7"],
      correctAnswer: "5"
    },
    {
      question: "The area of a rectangle with length 8 cm and width 5 cm is:",
      options: ["13 cm²", "40 cm²", "30 cm²", "45 cm²"],
      correctAnswer: "40 cm²"
    },
    {
      question: "The volume of a cube with edge length 4 cm is:",
      options: ["16 cm³", "64 cm³", "48 cm³", "32 cm³"],
      correctAnswer: "64 cm³"
    },
    {
      question: "The surface area of a cube with edge length 3 cm is:",
      options: ["27 cm²", "54 cm²", "81 cm²", "9 cm²"],
      correctAnswer: "54 cm²"
    },
    {
      question: "The surface area of a cylinder with radius 2 cm and height 5 cm is:",
      options: ["60 cm²", "40 cm²", "100 cm²", "80 cm²"],
      correctAnswer: "60 cm²"
    },
    {
      question: "If a bar graph shows that the number of students in different classes is as follows: Class A - 20, Class B - 30, Class C - 25, which class has the maximum number of students?",
      options: ["Class A", "Class B", "Class C", "All have the same number"],
      correctAnswer: "Class B"
    },
    {
      question: "The mean of the data set {3, 5, 7, 9, 11} is:",
      options: ["7", "6", "8", "9"],
      correctAnswer: "7"
    }
  ],  
};


// Grade 9 Science
export const gr9sci = {
  data: "CBSE Content for Class 9 Science",
  list:[
    {abelableContent: "Chapter 1 Matter in Our Surroundings"},
    {abelableContent: "Chapter 2 Is Matter Around Us Pure"},
    {abelableContent: "Chapter 3 Atoms and Molecules"},
    {abelableContent: "Chapter 4 Structure of the Atom"},
    {abelableContent: "Chapter 5 The Fundamental Unit of Life"},
    {abelableContent: "Chapter 6 Tissues"},
    {abelableContent: "Chapter 7 Diversity in Living Organisms"},
    {abelableContent: "Chapter 8 Motion"},
    {abelableContent: "Chapter 9 Force and Laws of Motion"},
    {abelableContent: "Chapter 10 Gravitation"},
    {abelableContent: "Chapter 11 Work, Power And Energy"},
    {abelableContent: "Chapter 12 Sound"},
    {abelableContent: "Chapter 13 Why Do we Fall Ill"},
    {abelableContent: "Chapter 14 Natural Resources"},
    {abelableContent: "Chapter 15 Improvement in Food Resources"},
  ],
  questions: [
    {
      "question": "Which of the following is not a physical change?",
      "options": ["Melting of ice", "Boiling of water", "Rusting of iron", "Dissolving sugar in water"],
      "correctAnswer": "Rusting of iron"
    },
    {
      "question": "The process of changing a solid directly into a gas without passing through the liquid state is called:",
      "options": ["Sublimation", "Condensation", "Freezing", "Evaporation"],
      "correctAnswer": "Sublimation"
    },
    {
      "question": "Which of the following is a homogeneous mixture?",
      "options": ["Sand and salt", "Oil and water", "Air", "Concrete"],
      "correctAnswer": "Air"
    },
    {
      "question": "What is the main difference between a compound and a mixture?",
      "options": [
        "A compound can be separated into its components by physical means, while a mixture cannot.",
        "A mixture has a fixed ratio of its components, while a compound does not.",
        "A compound has a fixed ratio of its components, while a mixture does not.",
        "A mixture has a definite composition, while a compound does not."
      ],
      "correctAnswer": "A compound has a fixed ratio of its components, while a mixture does not."
    },
    {
      "question": "Which of the following elements has atoms that consist of only one type of particle?",
      "options": ["Helium", "Sodium chloride", "Water", "Carbon dioxide"],
      "correctAnswer": "Helium"
    },
    {
      "question": "The molecular formula of a compound is:",
      "options": [
        "The simplest ratio of atoms in the compound",
        "The actual number of atoms of each element in a molecule",
        "The total number of atoms present in the compound",
        "The ratio of different elements by weight in the compound"
      ],
      "correctAnswer": "The actual number of atoms of each element in a molecule"
    },
    {
      "question": "The charge of an electron is:",
      "options": ["Positive", "Negative", "Neutral", "It depends on the atom"],
      "correctAnswer": "Negative"
    },
    {
      "question": "Who is credited with the discovery of the electron?",
      "options": ["Ernest Rutherford", "J.J. Thomson", "Niels Bohr", "John Dalton"],
      "correctAnswer": "J.J. Thomson"
    },
    {
      "question": "Which organelle is known as the powerhouse of the cell?",
      "options": ["Nucleus", "Ribosome", "Mitochondria", "Endoplasmic reticulum"],
      "correctAnswer": "Mitochondria"
    },
    {
      "question": "Which of the following is found in plant cells but not in animal cells?",
      "options": ["Cell membrane", "Cytoplasm", "Chloroplast", "Nucleus"],
      "correctAnswer": "Chloroplast"
    },
    {
      "question": "Which type of tissue provides structural support and protection?",
      "options": ["Epithelial tissue", "Connective tissue", "Muscular tissue", "Nervous tissue"],
      "correctAnswer": "Connective tissue"
    },
    {
      "question": "Which type of connective tissue connects muscles to bones?",
      "options": ["Adipose tissue", "Cartilage", "Tendons", "Blood"],
      "correctAnswer": "Tendons"
    },
    {
      "question": "Which of the following is not a characteristic of animals in the kingdom Animalia?",
      "options": ["They are multicellular.", "They are heterotrophic.", "They have cell walls.", "They are eukaryotic."],
      "correctAnswer": "They have cell walls."
    },
    {
      "question": "The scientific study of plants is known as:",
      "options": ["Zoology", "Botany", "Entomology", "Microbiology"],
      "correctAnswer": "Botany"
    },
    {
      "question": "What is the SI unit of velocity?",
      "options": ["Meter", "Meter per second", "Meter per second squared", "Newton"],
      "correctAnswer": "Meter per second"
    },
    {
      "question": "If a car travels 150 km in 3 hours, what is its average speed?",
      "options": ["50 km/h", "75 km/h", "100 km/h", "150 km/h"],
      "correctAnswer": "50 km/h"
    },
    {
      "question": "Newton's first law of motion states that:",
      "options": [
        "Force equals mass times acceleration",
        "For every action, there is an equal and opposite reaction",
        "An object will remain at rest or in uniform motion unless acted upon by an external force",
        "Acceleration is directly proportional to force and inversely proportional to mass"
      ],
      "correctAnswer": "An object will remain at rest or in uniform motion unless acted upon by an external force"
    },
    {
      "question": "What is the SI unit of force?",
      "options": ["Joule", "Watt", "Newton", "Pascal"],
      "correctAnswer": "Newton"
    },
    {
      "question": "The SI unit of work is:",
      "options": ["Joule", "Watt", "Newton", "Kelvin"],
      "correctAnswer": "Joule"
    },
    {
      "question": "What is the principle of conservation of energy?",
      "options": [
        "Energy can neither be created nor destroyed, only converted from one form to another",
        "Energy can be created and destroyed, but not converted",
        "Energy is only conserved in mechanical systems",
        "Energy conservation is applicable only in thermodynamics"
      ],
      "correctAnswer": "Energy can neither be created nor destroyed, only converted from one form to another"
    }
  ],  
};
	

// Grade 9 Maths
export const gr9mat = {
  data: "CBSE Content for Class 9 Maths",
  list:[
    {abelableContent: "Chapter 1 Number systems"},
    {abelableContent: "Chapter 2 Polynomials"},
    {abelableContent: "Chapter 3 Coordinate Geometry"},
    {abelableContent: "Chapter 4 Linear Equations in Two Variables"},
    {abelableContent: "Chapter 5 Introduction to Euclid Geometry"},
    {abelableContent: "Chapter 6 Lines and Angles"},
    {abelableContent: "Chapter 7 Triangles"},
    {abelableContent: "Chapter 8 Quadrilaterals"},
    {abelableContent: "Chapter 9 Areas of Parallelograms and Triangles"},
    {abelableContent: "Chapter 10 Circles"},
    {abelableContent: "Chapter 11 Constructions"},
    {abelableContent: "Chapter 12 Heron’s Formula"},
    {abelableContent: "Chapter 13 Surface Areas and Volumes"},
    {abelableContent: "Chapter 14 Statistics"},
    {abelableContent: "Chapter 15 Probability"},
  ],
  questions: [
    {
      "question": "What is the decimal equivalent of the binary number 101110111011?",
      "options": ["11", "10", "9", "8"],
      "correctAnswer": "11"
    },
    {
      "question": "Which of the following is an irrational number?",
      "options": ["23\\frac{2}{3}", "4\\sqrt{4}", "2\\sqrt{2}", "72\\frac{7}{2}"],
      "correctAnswer": "2\\sqrt{2}"
    },
    {
      "question": "If P(x)=3x^2−5x+2, what is P(2)?",
      "options": ["8", "6", "4", "10"],
      "correctAnswer": "8"
    },
    {
      "question": "Which of the following is not a factor of x^2 - 1?",
      "options": ["x + 1", "x - 1", "x^2 + 1", "x - 1"],
      "correctAnswer": "x^2 + 1"
    },
    {
      "question": "The distance between the points (3,4) and (7,1) is:",
      "options": ["5", "6", "7", "8"],
      "correctAnswer": "5"
    },
    {
      "question": "What is the midpoint of the line segment joining the points (2,3) and (4,7)?",
      "options": ["(3,5)", "(3,6)", "(4,5)", "(2,4)"],
      "correctAnswer": "(3,6)"
    },
    {
      "question": "Solve the system of equations: x+y=7 and 2x−y=4. What are the values of x and y?",
      "options": ["x=3, y=4", "x=4, y=3", "x=5, y=2", "x=2, y=5"],
      "correctAnswer": "x=4, y=3"
    },
    {
      "question": "The graphical representation of a linear equation in two variables is:",
      "options": ["A parabola", "A circle", "A straight line", "A hyperbola"],
      "correctAnswer": "A straight line"
    },
    {
      "question": "In Euclidean geometry, a point is:",
      "options": [
        "A part of a line",
        "A line with length but no width",
        "A location with no length, width, or thickness",
        "A surface with no thickness"
      ],
      "correctAnswer": "A location with no length, width, or thickness"
    },
    {
      "question": "According to Euclid’s axioms, through any two points, there is:",
      "options": ["No line", "One and only one line", "Two lines", "An infinite number of lines"],
      "correctAnswer": "One and only one line"
    },
    {
      "question": "If two lines are cut by a transversal and the alternate interior angles are equal, then the lines are:",
      "options": ["Perpendicular", "Parallel", "Intersecting", "Coinciding"],
      "correctAnswer": "Parallel"
    },
    {
      "question": "The sum of the angles in a triangle is:",
      "options": ["90°", "180°", "360°", "270°"],
      "correctAnswer": "180°"
    },
    {
      "question": "In a triangle, if one angle is 90°, then the triangle is:",
      "options": ["Isosceles", "Equilateral", "Scalene", "Right-angled"],
      "correctAnswer": "Right-angled"
    },
    {
      "question": "Which of the following is not a property of an isosceles triangle?",
      "options": [
        "Two sides are equal",
        "Two angles are equal",
        "The third angle is always 90°",
        "The sides opposite to equal angles are equal"
      ],
      "correctAnswer": "The third angle is always 90°"
    },
    {
      "question": "The sum of the interior angles of a quadrilateral is:",
      "options": ["180°", "360°", "270°", "90°"],
      "correctAnswer": "360°"
    },
    {
      "question": "In a parallelogram, opposite angles are:",
      "options": ["Supplementary", "Complementary", "Congruent", "Obtuse"],
      "correctAnswer": "Congruent"
    },
    {
      "question": "The area of a triangle is given by:",
      "options": [
        "\\frac{1}{2} \\times \\text{base} \\times \\text{height}",
        "\\text{base} \\times \\text{height}",
        "\\frac{1}{2} \\times \\text{base} \\times \\text{side}",
        "\\text{side} \\times \\text{side}"
      ],
      "correctAnswer": "\\frac{1}{2} \\times \\text{base} \\times \\text{height}"
    },
    {
      "question": "The area of a parallelogram is given by:",
      "options": ["\\text{base} \\times \\text{height}", "\\frac{1}{2} \\times \\text{base} \\times \\text{height}", "\\text{side} \\times \\text{side}", "\\frac{1}{2} \\times (\text{base} + \text{height})"],
      "correctAnswer": "\\text{base} \\times \\text{height}"
    },
    {
      "question": "The circumference of a circle is given by:",
      "options": ["2\\pi r", "\\pi r^2", "2\\pi r^2", "\\pi d"],
      "correctAnswer": "2\\pi r"
    },
    {
      "question": "A diameter of a circle is:",
      "options": [
        "A line segment joining any two points on the circle",
        "A line segment joining the center to a point on the circle",
        "A line segment joining the center to a point on the circle through another point on the circle",
        "A line segment passing through the center and connecting two points on the circle"
      ],
      "correctAnswer": "A line segment passing through the center and connecting two points on the circle"
    }
  ],  
};


// Grade 10 Science
export const gr10sci = {
  data: "CBSE Content for Class 10 Science",
  list:[
    {abelableContent: "Chapter 1 : Chemical Reactions and Equations"},
    {abelableContent: "Chapter 2 : Acids, Bases, and Salts"},
    {abelableContent: "Chapter 3 : Metals and Non-metals"},
    {abelableContent: "Chapter 4 : Carbon and its Compounds"},
    {abelableContent: "Chapter 5 : Life Processes"},
    {abelableContent: "Chapter 6 : Control and Coordination"},
    {abelableContent: "Chapter 7 : How do Organisms Reproduce?"},
    {abelableContent: "Chapter 8 : Heredity"},
    {abelableContent: "Chapter 9 : Light – Reflection and Refraction"},
    {abelableContent: "Chapter 10 : The Human Eye and the Colourful World"},
    {abelableContent: "Chapter 11 : Electricity"},
    {abelableContent: "Chapter 12 : Magnetic Effects of Electric Current"},
    {abelableContent: "Chapter 13 : Our Environment"}
  ],
  questions: [
    {
      "question": "In a chemical reaction, if 2H2 + O2 → 2H2O, how many moles of water are produced when 2 moles of hydrogen react with 1 mole of oxygen?",
      "options": ["1 mole", "2 moles", "4 moles", "6 moles"],
      "correctAnswer": "2 moles"
    },
    {
      "question": "Which of the following is an example of a redox reaction?",
      "options": ["Neutralization reaction", "Precipitation reaction", "Combustion of hydrogen", "Decomposition of calcium carbonate"],
      "correctAnswer": "Combustion of hydrogen"
    },
    {
      "question": "What is the pH range of a basic solution?",
      "options": ["0-7", "7-14", "0-14", "1-14"],
      "correctAnswer": "7-14"
    },
    {
      "question": "Which of the following salts is formed by the reaction of a strong acid and a weak base?",
      "options": ["Sodium chloride", "Potassium nitrate", "Ammonium chloride", "Calcium sulfate"],
      "correctAnswer": "Ammonium chloride"
    },
    {
      "question": "Which of the following metals does not react with acids?",
      "options": ["Zinc", "Iron", "Copper", "Magnesium"],
      "correctAnswer": "Copper"
    },
    {
      "question": "The property of non-metals to gain electrons is called:",
      "options": ["Reduction", "Oxidation", "Ionization", "Electron affinity"],
      "correctAnswer": "Electron affinity"
    },
    {
      "question": "Which of the following is a hydrocarbon?",
      "options": ["Methanol", "Ethanoic acid", "Butane", "Sodium bicarbonate"],
      "correctAnswer": "Butane"
    },
    {
      "question": "What is the general formula for alkanes?",
      "options": ["CnH2n", "CnH2n+1", "CnH2n+2", "CnH2n−2"],
      "correctAnswer": "CnH2n+2"
    },
    {
      "question": "The element with atomic number 11 is:",
      "options": ["Sodium", "Magnesium", "Aluminum", "Potassium"],
      "correctAnswer": "Sodium"
    },
    {
      "question": "Which of the following elements has the highest atomic radius?",
      "options": ["Fluorine", "Oxygen", "Nitrogen", "Lithium"],
      "correctAnswer": "Lithium"
    },
    {
      "question": "The process by which plants convert light energy into chemical energy is known as:",
      "options": ["Respiration", "Photosynthesis", "Digestion", "Fermentation"],
      "correctAnswer": "Photosynthesis"
    },
    {
      "question": "The main function of the large intestine is:",
      "options": ["Absorption of nutrients", "Digestion of proteins", "Absorption of water and salts", "Production of digestive enzymes"],
      "correctAnswer": "Absorption of water and salts"
    },
    {
      "question": "Which part of the nervous system is responsible for transmitting signals from the brain to the muscles?",
      "options": ["Sensory neurons", "Motor neurons", "Interneurons", "All of the above"],
      "correctAnswer": "Motor neurons"
    },
    {
      "question": "The endocrine gland responsible for regulating metabolism is:",
      "options": ["Thyroid gland", "Pituitary gland", "Adrenal gland", "Pancreas"],
      "correctAnswer": "Thyroid gland"
    },
    {
      "question": "Which of the following is an asexual method of reproduction in plants?",
      "options": ["Pollination", "Vegetative propagation", "Seed formation", "Cross-pollination"],
      "correctAnswer": "Vegetative propagation"
    },
    {
      "question": "The process of cell division that results in the production of gametes is called:",
      "options": ["Mitosis", "Meiosis", "Binary fission", "Budding"],
      "correctAnswer": "Meiosis"
    },
    {
      "question": "The physical expression of a gene is known as its:",
      "options": ["Genotype", "Phenotype", "Allele", "Locus"],
      "correctAnswer": "Phenotype"
    },
    {
      "question": "Who is known as the father of genetics?",
      "options": ["Charles Darwin", "Gregor Mendel", "Louis Pasteur", "Watson and Crick"],
      "correctAnswer": "Gregor Mendel"
    },
    {
      "question": "The phenomenon of light bending as it passes from one medium to another is known as:",
      "options": ["Reflection", "Refraction", "Diffraction", "Dispersion"],
      "correctAnswer": "Refraction"
    },
    {
      "question": "The lens used to correct hyperopia (farsightedness) is:",
      "options": ["Convex lens", "Concave lens", "Bifocal lens", "Cylindrical lens"],
      "correctAnswer": "Convex lens"
    },
    {
      "question": "The SI unit of electric current is:",
      "options": ["Volt", "Ohm", "Ampere", "Watt"],
      "correctAnswer": "Ampere"
    },
    {
      "question": "Ohm’s law states that the current through a conductor is directly proportional to the:",
      "options": ["Resistance", "Voltage", "Power", "Energy"],
      "correctAnswer": "Voltage"
    },
    {
      "question": "The right-hand rule is used to determine the direction of the:",
      "options": ["Electric field", "Magnetic field", "Current", "Voltage"],
      "correctAnswer": "Magnetic field"
    },
    {
      "question": "The device used to measure the strength of a magnetic field is called:",
      "options": ["Ammeter", "Voltmeter", "Galvanometer", "Magnetometer"],
      "correctAnswer": "Magnetometer"
    },
    {
      "question": "Which of the following is a non-renewable source of energy?",
      "options": ["Solar energy", "Wind energy", "Coal", "Biomass"],
      "correctAnswer": "Coal"
    },
    {
      "question": "The primary source of energy for the Earth is:",
      "options": ["Geothermal energy", "Nuclear energy", "Solar energy", "Tidal energy"],
      "correctAnswer": "Solar energy"
    }
  ],  
};

// Grade 10 Maths
export const gr10mat = {
  data: "CBSE Content for Class 10 Maths",
  list:[
    {abelableContent: "Chapter 1 Real Numbers"},
    {abelableContent: "Chapter 2 Polynomials"},
    {abelableContent: "Chapter 3 Pair of Linear Equations in Two Variables"},
    {abelableContent: "Chapter 4 Quadratic Equations"},
    {abelableContent: "Chapter 5 Arithmetic Progressions"},
    {abelableContent: "Chapter 6 Triangles"},
    {abelableContent: "Chapter 7 Coordinate Geometry"},
    {abelableContent: "Chapter 8 Introduction to Trigonometry"},
    {abelableContent: "Chapter 9 Some Applications of Trigonometry"},
    {abelableContent: "Chapter 10 Circles"},
    {abelableContent: "Chapter 11 Areas Related to Circles"},
    {abelableContent: "Chapter 12 Surface Areas and Volumes"},
    {abelableContent: "Chapter 13 Statistics"},
    {abelableContent: "Chapter 14 Probability"}
  ],
  questions: [
      {
        "question": "What is the HCF of 24 and 36?",
        "options": ["6", "12", "24", "36"],
        "correctAnswer": "12"
      },
      {
        "question": "Which of the following numbers is not a rational number?",
        "options": ["\\frac{7}{3}", "2\\sqrt{2}", "\\frac{5}{4}", "-3"],
        "correctAnswer": "2\\sqrt{2}"
      },
      {
        "question": "If P(x) = x^2 - 4x + 4, what are the roots of the polynomial?",
        "options": ["2 and 2", "1 and 4", "-2 and -2", "0 and 4"],
        "correctAnswer": "2 and 2"
      },
      {
        "question": "Which of the following is not a factor of x^2 - 2x - 3?",
        "options": ["x - 3", "x + 1", "x - 1", "x + 3"],
        "correctAnswer": "x - 1"
      },
      {
        "question": "Solve the system of equations: 2x + 3y = 5 and x - y = 1. What are the values of x and y?",
        "options": ["x=2, y=1", "x=1, y=2", "x=3, y=0", "x=0, y=3"],
        "correctAnswer": "x=2, y=1"
      },
      {
        "question": "What is the graphical representation of a consistent and dependent system of linear equations?",
        "options": ["Parallel lines", "Intersecting lines at a single point", "Coinciding lines", "Lines that do not intersect"],
        "correctAnswer": "Coinciding lines"
      },
      {
        "question": "The roots of the quadratic equation x^2 - 5x + 6 = 0 are:",
        "options": ["1 and 6", "2 and 3", "-2 and -3", "1 and 5"],
        "correctAnswer": "2 and 3"
      },
      {
        "question": "What is the discriminant of the quadratic equation 2x^2 - 4x + 1 = 0?",
        "options": ["0", "1", "-4", "2"],
        "correctAnswer": "-4"
      },
      {
        "question": "In a triangle, if one angle is 90°, then the triangle is:",
        "options": ["Scalene", "Isosceles", "Right-angled", "Equilateral"],
        "correctAnswer": "Right-angled"
      },
      {
        "question": "Which of the following is true about the sum of the angles in a triangle?",
        "options": ["90°", "120°", "180°", "360°"],
        "correctAnswer": "180°"
      },
      {
        "question": "What is the midpoint of the line segment joining (4, 5) and (8, 13)?",
        "options": ["(6, 9)", "(5, 9)", "(6, 7)", "(7, 8)"],
        "correctAnswer": "(6, 9)"
      },
      {
        "question": "The distance between the points (3, 4) and (7, 8) is:",
        "options": ["5", "6", "7", "8"],
        "correctAnswer": "5"
      },
      {
        "question": "In a right-angled triangle, if the length of the opposite side is 3 and the length of the hypotenuse is 5, what is sin θ?",
        "options": ["\\frac{3}{5}", "\\frac{4}{5}", "\\frac{5}{3}", "\\frac{3}{4}"],
        "correctAnswer": "\\frac{3}{5}"
      },
      {
        "question": "What is the value of cos 90°?",
        "options": ["1", "0", "-1", "2\\sqrt{2}"],
        "correctAnswer": "0"
      },
      {
        "question": "The area of a circle with radius 7 cm is:",
        "options": ["49π cm²", "14π cm²", "49π cm", "14π cm"],
        "correctAnswer": "49π cm²"
      },
      {
        "question": "The circumference of a circle is given by 2πr. If the radius is 5 cm, what is the circumference?",
        "options": ["10π cm", "25π cm", "5π cm", "30π cm"],
        "correctAnswer": "10π cm"
      },
      {
        "question": "The surface area of a cube with edge length 4 cm is:",
        "options": ["64 cm²", "96 cm²", "16 cm²", "32 cm²"],
        "correctAnswer": "96 cm²"
      },
      {
        "question": "The volume of a cylinder with radius 3 cm and height 5 cm is:",
        "options": ["45π cm³", "90π cm³", "15π cm³", "30π cm³"],
        "correctAnswer": "90π cm³"
      },
      {
        "question": "The median of the data set {3, 7, 5, 9, 12} is:",
        "options": ["5", "7", "9", "12"],
        "correctAnswer": "7"
      },
      {
        "question": "What is the mode of the data set {2, 3, 5, 3, 7, 3, 9}?",
        "options": ["2", "5", "7", "3"],
        "correctAnswer": "3"
      }
    ],  
};








// ICSE
// ICSE Grade 6 Science
export const icse6sci = {
  data: "ICSE Content for Class 6 Science",
  list:[
    {abelableContent: "Physics: Introduction to light, sound, and electricity"},
    {abelableContent: "Chemistry: Basics of matter, elements, and compounds"},
    {abelableContent: "Biology: Study of plants, animals, and the human body"},
    {abelableContent: "Environmental Science: Awareness of environmental issues and conservation"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 6 Maths
export const icse6mat = {
  data: "ICSE Content for Class 6 Maths",
  list:[
    {abelableContent: "Number System: Integers, fractions, decimals, and powers and roots"},
    {abelableContent: "Algebra: Basic algebraic expressions and equations"},
    {abelableContent: "Geometry: Basic geometry concepts"},
    {abelableContent: "Mensuration: Introduction to concepts like area and perimeter"},
    {abelableContent: "Data Handling: Introduction to data interpretation and representation"},
    {abelableContent: "Simple Equations"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};

// ICSE Grade 7 Science
export const icse7sci = {
  data: "ICSE Content for Class 7 Science",
  list:[
    {abelableContent: "Physics: Concepts related to motion, force, heat, and light"},
    {abelableContent: "Chemistry: Basics of elements, compounds, and chemical reactions"},
    {abelableContent: "Biology: Study of plants, animals, and human body systems"},
    {abelableContent: "Environmental Science: Awareness of environmental issues and conservation"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 7 Maths
export const icse7mat = {
  data: "ICSE Content for Class 7 Maths",
  list:[
    {abelableContent: "Number System: Fractions, decimals, percentages, and rational numbers"},
    {abelableContent: "Algebra: Algebraic expressions and equations"},
    {abelableContent: "Geometry: Plane and solid geometry"},
    {abelableContent: "Mensuration: Area, volume, and surface area calculations"},
    {abelableContent: "Data Handling: Collection, organization, and interpretation of data"},
    {abelableContent: "Simple Equations and Ratio and Proportion"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};

// ICSE Grade 8 Science
export const icse8sci = {
  data: "ICSE Content for Class 8 Science",
  list:[
    {abelableContent: "Physics: Concepts related to motion, force, light, sound, and electricity"},
    {abelableContent: "Chemistry: Elements, compounds, chemical reactions, and the periodic table"},
    {abelableContent: "Biology: Study of human body systems, health, and diseases"},
    {abelableContent: "Environmental Science: Awareness of environmental issues, conservation, and ecosystem"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 8 Maths
export const icse8mat = {
  data: "ICSE Content for Class 8 Maths",
  list:[
    {abelableContent: "Number System: Rational numbers, integers, and number line"},
    {abelableContent: "Algebra: Linear equations, exponents, and polynomials"},
    {abelableContent: "Geometry: Plane and solid geometry, congruence, and similarity"},
    {abelableContent: "Mensuration: Area, volume, and surface area calculations"},
    {abelableContent: "Data Handling: Statistics and probability"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 9 Science
export const icse9sci = {
  data: "ICSE Content for Class 9 Science",
  list:[
    {abelableContent: "Physics: Advanced topics in mechanics, energy, and electricity"},
    {abelableContent: "Chemistry: Chemical reactions, the periodic table, and chemical bonding"},
    {abelableContent: "Biology: Study of cells, tissues, and the human circulatory system"},
    {abelableContent: "Environmental Science: Deeper exploration of environmental issues and conservation"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 9 Maths
export const icse9mat = {
  data: "ICSE Content for Class 9 Maths",
  list:[
    {abelableContent: "Number System: Real numbers, rational and irrational numbers, and laws of exponents"},
    {abelableContent: "Algebra: Polynomials, linear equations in two variables, and quadratic equations"},
    {abelableContent: "Geometry: Properties of triangles, quadrilaterals, and circles"},
    {abelableContent: "Mensuration: Areas and volumes"},
    {abelableContent: "Coordinate Geometry"},
    {abelableContent: "Probability and Statistics"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 10 Science
export const icse10sci = {
  data: "ICSE Content for Class 10 Science",
  list:[
    {abelableContent: "Physics: Topics include light, electricity, magnetism, and modern physics"},
    {abelableContent: "Chemistry: Chemical reactions, periodic table, chemical bonding, and organic chemistry"},
    {abelableContent: "Biology: Study of cells, tissues, and the human circulatory system, along with environmental topics"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
// ICSE Grade 10 Maths
export const icse10mat = {
  data: "ICSE Content for Class 10 Maths",
  list:[
    {abelableContent: "Real Numbers, Rational Numbers, and Laws of Exponents"},
    {abelableContent: "Quadratic Equations and Arithmetic Progressions"},
    {abelableContent: "Coordinate Geometry"},
    {abelableContent: "Geometry: Properties of triangles, quadrilaterals, and circles"},
    {abelableContent: "Trigonometry"},
    {abelableContent: "Mensuration: Areas and volumes"},
    {abelableContent: "Probability and Statistics"}
  ],
  questions: [
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
    {
      question: "What is your favorite game?",
      options: ["Redboll", "Blueboll", "Greenboll", "Yellowboll"],
      correctAnswer: "Yellowboll",
    },
    {
      question: "What is your favorite food?",
      options: ["Pizza", "Burger", "Sushi", "Pasta"],
      correctAnswer: "Pizza",
    },
  ],
};
