import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SentimentVeryDissatisfiedRoundedIcon from "@mui/icons-material/SentimentVeryDissatisfiedRounded";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
// import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../../context/AuthContext";
import { AuthContext } from "../../../context/AuthContext";
import Modal from "react-modal";

Modal.setAppElement("#root");
const Sidebar = () => {
  const { user } = useContext(AuthContext);
  // const [selectedDays, setSelectedDays] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    startDate: "",
    startTime: "",
    endTime: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [authorizationUrl, setAuthorizationUrl] = useState("");
  const [BatchDates, setBatchDates] = useState([]);
  const handleLogout = async () => {
    // dispatch({ type: "LOGOUT_START" });
    try {
      localStorage.removeItem("user");
      const res = await axios.post("https://api.studentrays.com/api/auth/logout"); // Send a request to your backend logout endpoint
      if (res.status === 200) {
        // dispatch({ type: "LOGOUT_SUCCESS" });
        navigate("/counsellorlogin"); // Redirect to your login page or any other desired page
      } else {
        // dispatch({ type: "LOGOUT_FAILURE" });
      }
    } catch (err) {
      // dispatch({ type: "LOGOUT_FAILURE" });
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const getDayName = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: "long" };
    return date.toLocaleDateString("en-US", options).toLowerCase();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const course = "cbse";
    const grade = "8";
    const subject = "science";
    const section = "b";
    const batch = "councellor";
    // const startDate = formData.startDate;
    // const endDate = formData.startDate;
    // Assuming formData.startDate is in "YYYY-MM-DD" format
    const startDate = new Date(formData.startDate);
    const endDate = new Date(formData.startDate);

    // Decrease 1 day from startDate
    // startDate.setDate(startDate.getDate() - 1);

    // Increase 1 day to endDate
    // endDate.setDate(endDate.getDate() + 1);

    // Function to format the date in "YYYY-MM-DD"
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const adjustedStartDate = formatDate(startDate);
    const adjustedEndDate = formatDate(endDate);
    const startTime = formData.startTime;
    const endTime = formData.endTime;
    const selectedDays = [getDayName(startDate)];
    const requestData = {
      course,
      grade,
      subject,
      section,
      batch,
      startDate: adjustedStartDate,
      endDate: adjustedEndDate,
      startTime,
      endTime,
      selectedDays,
    };

    localStorage.setItem("request", JSON.stringify(requestData));
    console.log("requestData", requestData);
    setModalIsOpen(false);
  };
  const createMeeting = async () => {
    try {
      const response = await axios.get(`https://api.studentrays.com/api/auth/zoom_Counc`);
      console.log(response.data.authorizationUrl, 12);
      window.location.href = response.data.authorizationUrl;
    } catch (error) {
      console.error("Error initiating Zoom OAuth:", error);
    }
  };

  return (
    <div className="sidebar" style={{ "margin-top": "0rem" }}>
      <div className="top">
        <Link to="/counsellordashboard" style={{ textDecoration: "none" }}>
          <span className="logo"> Counsellor</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/counsellordashboard" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">LISTS</p>
          <Link
            to="/counsellordashboard/freesessions"
            style={{ textDecoration: "none" }}
          >
            <li>
              <SentimentVeryDissatisfiedRoundedIcon className="icon" />
              <span>Freesession</span>
            </li>
          </Link>
          <Link
            to="/counsellordashboard/complains"
            style={{ textDecoration: "none" }}
          >
            <li>
              <SentimentVeryDissatisfiedRoundedIcon className="icon" />
              <span>Complain</span>
            </li>
          </Link>
          {/* <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/datas" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Data</span>
            </li>
          </Link>
          <Link to="/users/students" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" />
              <span>Students</span>
            </li>
          </Link> */}
          {/* <Link to="/users/teachers" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" />
              <span>Teachers</span>
            </li>
          </Link>
          <Link to="/users/counsellors" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" />
              <span>Counsellors</span>
            </li>
          </Link> */}
          {/* <li>
            <LocalShippingIcon className="icon" />
            <span>Delivery</span>
          </li> */}

          {/* <p className="title">DETAIL</p> */}
          {/* <Link to="/students" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Student</span>
            </li>
          </Link> */}
          {/* <li>
            <NotificationsNoneIcon className="icon" />
            <span>Notifications</span>
          </li> */}
          {/* <p className="title">USEFUL</p>
          <li>
            <InsertChartIcon className="icon" />
            <span>Stats</span>
          </li>
          <li>
            <NotificationsNoneIcon className="icon" />
            <span>Notifications</span>
          </li>
          <p className="title">SERVICE</p>
          <li>
            <SettingsSystemDaydreamOutlinedIcon className="icon" />
            <span>System Health</span>
          </li>
          <li>
            <PsychologyOutlinedIcon className="icon" />
            <span>Logs</span>
          </li>
          <li>
            <SettingsApplicationsIcon className="icon" />
            <span>Settings</span>
          </li>*/}
          <p className="title">USER</p>
          {/* <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>  */}
          <li onClick={handleLogout}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
          <p className="title">Meeting</p>
          <button onClick={() => setModalIsOpen(true)}>Open Form</button>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Form Modal"
            style={{ width: "30%", height: "30%", inset: "30%" }}
          >
            <h2>Enter Details</h2>
            <form
              onSubmit={handleSubmit}
              style={{ position: "unset", border: "0px solid black" }}
            >
              <div
                className="calendar"
                style={{ fontSize: "18px", textAlign: "left" }}
              >
                <div>
                  <label htmlFor="start-date">Select Date:</label>
                  <input
                    type="date"
                    id="start-date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="start-time">Start Time:</label>
                  <input
                    type="time"
                    id="start-time"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="end-time">End Time:</label>
                  <input
                    type="time"
                    id="end-time"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setModalIsOpen(false)}
                // style={{ fontSize: "14px" }}
              >
                Cancel
              </button>
            </form>
          </Modal>
          {/* <AccountCircleOutlinedIcon className="icon" /> */}
          <button
            className="btn btn-primary"
            onClick={createMeeting}
            style={{ marginTop: "10px", fontSize: "14px" }}
          >
            Auth Meeting
          </button>
          {!user.isTeacher && !user.isStudent ? (
                  <div className="joinClassLower">
                    <Link to={`/counsellordashboard/counc_calender`}>
                      <button className="btn btn-primary">View Calender</button>
                    </Link>
                  </div>
                ) : (
                  ""
                )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
