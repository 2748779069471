import React from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useContext, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { useState } from "react";
import "./header.css";
import VideoPages from "./VideoPages";
import { Link } from "react-router-dom";
import DashboardUserData from "../../components/dashboardUserData/DashboardUserData";
import OrderButton from "../Razorpay_button/OrderButton";
import EmailButton from "../EmailButton/EmailButton";
import CreateMeeting from "../ZoomJoin/CreateMeeting";
import GeneratePDFButton from "../generatePdf";
import moment from "moment";
// import axios from "axios";

const Header = () => {
  const [event, setEvent] = useState();
  const { user } = useContext(AuthContext);
  const userId = user._id;
  const userAssignedTeacher = user.teacherAssigned;
  const { data, loading, error } = useFetch("https://api.studentrays.com/api/datas/");
  const batch = user.batch[0];
  const {
    data: datas,
    loading: eventsLoading,
    error: eventsError,
  } = useFetch(`https://api.studentrays.com/api/calender/${user._id}`);
  console.log("dataevent", datas);
  // console.log("data", data);
  // console.log("meetinglink", datas.meetinglink);
  // console.log("teachermeetinglink", datas.teachermeetinglink);
  const [events, setEvents] = useState([]);
  const [ongoingEvent, setOngoingEvent] = useState(null);
  const [UpcomingCounselorMarker, setUpcomingCounselorMarker] = useState(null);
  const [nextClass, setNextClass] = useState(null);
  useEffect(() => {
    if (datas && datas.length > 0) {
      const currentTime = moment(); // Use the provided current time for testing
      // const currentTime = moment("2024-06-14T18:30:00"); // Use the provided current time for testing
      let nextClassTime = null;
      let nextClassBatch = null;
      let ongoingEventFound = false;

      const updatedEvents = datas.map((event, index) => {
        const { Date: eventDate, startTime, endTime, batch, joinUrl } = event;

        const [year, month, day] = eventDate.split("-").map(Number);
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);

        const start = moment({
          year,
          month: month - 1,
          day,
          hour: startHour,
          minute: startMinute,
        });
        const end = moment({
          year,
          month: month - 1,
          day,
          hour: endHour,
          minute: endMinute,
        });

        if (currentTime.isBetween(start, end, null, "[]")) {
          setOngoingEvent({
            id: index + 1,
            title: batch,
            start: start.toDate(),
            end: end.toDate(),
            url: joinUrl,
          });
          ongoingEventFound = true;
        }

        // Check for upcoming counselor meeting
        if (currentTime.isBefore(start) && batch === "councellor") {
          const duration = moment.duration(start.diff(currentTime));
          const hours = duration.hours();
          const minutes = duration.minutes();
          const days = duration.days();

          let timeRemaining = "";
          if (days > 0) {
            timeRemaining = `${days} day(s)`;
          } else if (hours > 0) {
            timeRemaining = `${hours} hour(s)`;
          } else if (minutes > 0) {
            timeRemaining = `${minutes} minute(s)`;
          }

          setUpcomingCounselorMarker({
            message: `You have a meeting with a councellor in ${timeRemaining}.`,
            // start: start.toDate(),
            start: start.toLocaleString(),
          });
        }

        if (
          start.isAfter(currentTime) &&
          (!nextClassTime || start.isBefore(nextClassTime))
        ) {
          nextClassTime = start;
          nextClassBatch = batch;
        }

        return {
          id: index + 1,
          title: batch,
          start: start.toDate(),
          end: end.toDate(),
          url: joinUrl,
        };
      });

      setEvents(updatedEvents);

      if (!ongoingEventFound && nextClassTime) {
        const duration = moment.duration(nextClassTime.diff(currentTime));
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();

        const timeLeftMessage = `Time left for next class (${nextClassBatch}): ${
          days > 0 ? days + " days " : ""
        }${hours > 0 ? hours + " hours " : ""}${minutes} minutes`;
        setNextClass(timeLeftMessage);
      }
    }
  }, [datas]);

  const all = data.filter((item) => (item.grade == user.grade ? item : ""));
  // console.log("all", all);

  return (
    <div>
      <div className="container">
        <DashboardUserData
          name={user.name}
          course={user.course}
          grade={user.grade}
          phone={user.phone}
          email={user.email}
          state={user.state}
        />
        {/* <h1>header</h1> */}
        <div className="joinClass">
          {UpcomingCounselorMarker && (
            <div className="joinClassUpper">
              <div className="joinClassInner">
                {UpcomingCounselorMarker.message} <br /> Meeting start at:
                {UpcomingCounselorMarker.start}
              </div>
            </div>
          )}
          <div className="joinClassLower">
            <Link to={"/dashboard/calender/"}>
              <button className="btn btn-primary">Calender</button>
            </Link>
          </div>
          {ongoingEvent ? (
            <div>
              <p>Join Meeting with {ongoingEvent.title}</p>
              <a
                href={ongoingEvent.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-primary">Join</button>
              </a>
            </div>
          ) : nextClass ? (
            <div>
              <p>{nextClass}</p>
            </div>
          ) : (
            <p>No upcoming event</p>
          )}
        </div>
        <div className="heading">Choose your Subject</div>
        <div className="subjectSelection">
          {all?.map((item, index) => {
            const { _id, grade, subject } = item;
            return (
              <div>
                <Link key={index} to={`/dashboard/${item._id}`}>
                  <div className="Selection">
                    <img
                      className="iconImage"
                      src="https://learn-assets.byjusweb.com/images/subjects/maths-8-abv.png"
                      alt="icon"
                    />
                    <div className="subjectName">{item.subject}</div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        {/* <div className="videoHead">Videos that may interest you</div>
        <div className="playerAreea">
          <VideoPages />
          <VideoPages />
          <VideoPages />
        </div> */}
      </div>
      {/* <Testdata key={item._id} id={item._id} grade={item.grade} subject={item.subject} /> */}
    </div>
  );
};

export default Header;
