// import React, { useState, useEffect, useContext } from "react";
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import moment from "moment";
// import useFetch from "../../../hooks/useFetch";
// import "./calender.css";
// import { useLocation, useNavigate } from "react-router-dom";

// const localizer = momentLocalizer(moment);

// const CounsellorCalender = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const id = location.pathname.split("/")[3];
//   const { data: userdata } = useFetch(`https://api.studentrays.com/api/users/${id}`);
//   console.log("userdata", userdata);
//   // let userId = "";
//   // let batch = "";
//   // let teacherAssigned = "";
//   // if (userdata && userdata.assignedid && userdata.assignedid.length > 0) {
//   //   const assigned = userdata.assignedid[0]; // Access the first item in assignedid array

//   //   userId = userdata._id;
//   //   batch = assigned.batch || ""; // Extract batch from assignedid
//   //   teacherAssigned = assigned.teacherAssigned || "empty"; // Extract teacherAssigned from assignedid
//   // } else {
//   //   batch = "";
//   //   teacherAssigned = "empty";
//   // }
//   // // let batch = "";
//   // // batch = userdata?.batch?.[0] ?? "";
//   // console.log("teacherAssigned", teacherAssigned);
//   // console.log("batch", batch);

//   // const { data: calender } = useFetch(
//   //   teacherAssigned && batch
//   //     ? `https://api.studentrays.com/api/calender/${userId}`
//   //     : null
//   // );
//   const { data: calender } = useFetch(
//     `https://api.studentrays.com/api/calender/${id}`
//   );
//   console.log("calenderss", calender);
//   // const { data: calender } = useFetch(
//   //   `https://api.studentrays.com/api/calender/${id}`
//   // );

//   const [events, setEvents] = useState([]);

//   useEffect(() => {
//     if (calender && calender.length > 0) {
//       const exampleEvent = calender.map((event, index) => {
//         const {
//           Date: eventDate,
//           startTime,
//           endTime,
//           joinUrl,
//           teacherUrl,
//           batch,
//         } = event;

//         const [year, month, day] = eventDate.split("-").map(Number);
//         const [startHour, startMinute] = startTime.split(":").map(Number);
//         const [endHour, endMinute] = endTime.split(":").map(Number);

//         const start = new Date(year, month - 1, day, startHour, startMinute);
//         const end = new Date(year, month - 1, day, endHour, endMinute);
//         return {
//           id: index + 1,
//           title: `${batch}`,
//           start,
//           end,
//           url: joinUrl,
//           batch,
//         };
//         // return {
//         //   id: index + 1,
//         //   title: `${batch}`, // Use batch ID in the title
//         //   start,
//         //   end,
//         //   url: teacherUrl,
//         //   batch, // Include the batch information
//         // };
//       });

//       setEvents(exampleEvent);
//     }
//   }, [calender]);

//   // Coloring Here
//   const eventStyleGetter = (event, start, end, isSelected) => {
//     const batchColors = [
//       "blue",
//       "red",
//       "green",
//       "purple",
//       "orange",
//       "pink",
//       "cyan",
//       "brown",
//       "yellow",
//       "magenta",
//     ];

//     const batchIndex = events.findIndex((e) => e.batch === event.batch);
//     const color = batchColors[batchIndex % batchColors.length];

//     const style = {
//       backgroundColor: color,
//       borderRadius: "5px",
//       opacity: 0.8,
//       color: "white",
//       border: "none",
//       display: "block",
//     };
//     return { style };
//   };

//   const handleSelectSlot = (slotInfo) => {
//     const selectedDate = moment(slotInfo.start).startOf("day");
//     const today = moment().startOf("day");

//     if (!selectedDate.isSame(today)) {
//       alert("Only today's date is selectable.");
//     } else {
//       // Handle selection for today's date if needed
//     }
//   };

//   const handleSelectEvent = (event, e) => {
//     e.preventDefault();
//     const eventDate = moment(event.start).startOf("day");
//     const today = moment().startOf("day");

//     if (eventDate.isSame(today)) {
//       window.open(event.url, "_blank").focus();
//     } else {
//       alert("Only today's events are clickable.");
//     }
//   };

//   const dateCellWrapper = ({ value, children }) => {
//     const date = moment(value).startOf("day");
//     const today = moment().startOf("day");

//     return React.cloneElement(React.Children.only(children), {
//       style: {
//         ...children.style,
//         backgroundColor: date.isSame(today) ? "white" : "#f0f0f0",
//         pointerEvents: date.isSame(today) ? "auto" : "none",
//       },
//     });
//   };
//   console.log("events", events);
//   return (
//     <div>
//       {events && (
//         <Calendar
//           localizer={localizer}
//           events={events}
//           startAccessor="start"
//           endAccessor="end"
//           selectable
//           eventPropGetter={eventStyleGetter}
//           views={["month", "week", "day"]}
//           defaultView="week"
//           popup
//           onSelectSlot={handleSelectSlot}
//           onSelectEvent={handleSelectEvent}
//           components={{ dateCellWrapper }}
//         />
//       )}
//     </div>
//   );
// };

// export default CounsellorCalender;
import React, { useState, useEffect, useContext } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import useFetch from "../../../hooks/useFetch";
import "./calender.css";
import { useLocation, useNavigate } from "react-router-dom";
// import { AuthContext } from "../../context/AuthContext";

const localizer = momentLocalizer(moment);

const CounsellorCalender = () => {
  // const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  // const userdata = user;
  // const navigate = useNavigate();
  // const location = useLocation();
  // const id = location.pathname.split("/")[3];
  //   const { data: userdata } = useFetch(`https://api.studentrays.com/api/users/${user._id}`);
  // console.log("userdata",userdata);
  let userId = "";
  let batch = "";
  let teacherAssigned = "";
  // if (userdata) {
  //   userId = userdata._id;
  //   batch = userdata?.batch?.[0] ?? "";
  //   teacherAssigned = userdata.teacherAssigned;
  // }
  // if (userdata && userdata.assignedid && userdata.assignedid.length > 0) {
  //   const assigned = userdata.assignedid[0]; // Access the first item in assignedid array
  
  //   userId = userdata._id;
  //   batch = assigned.batch || ""; // Extract batch from assignedid
  //   teacherAssigned = assigned.teacherAssigned || "empty"; // Extract teacherAssigned from assignedid
  // } else {
  //   batch = "";
  //   teacherAssigned = "empty";
  // }
  
  // let batch = "";
  // batch = userdata?.batch?.[0] ?? "";
  // console.log("teacherAssigned", teacherAssigned);
  // console.log("batch", batch);

  // const { data: calender } = useFetch(
  //   teacherAssigned && batch
  //     ? `https://api.studentrays.com/api/calender/${teacherAssigned}/${batch}/${userId}`
  //     : null
  // );
  const { data: calender } = useFetch(`https://api.studentrays.com/api/calender/${id}`);
  console.log("calender", calender);
  // const { data: calender } = useFetch(
  //   `https://api.studentrays.com/api/calender/${id}`
  // );

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (calender && calender.length > 0) {
      const exampleEvent = calender.map((event, index) => {
        const {
          Date: eventDate,
          startTime,
          endTime,
          joinUrl,
          teacherUrl,
          batch,
        } = event;

        const [year, month, day] = eventDate.split("-").map(Number);
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);

        const start = new Date(year, month - 1, day, startHour, startMinute);
        const end = new Date(year, month - 1, day, endHour, endMinute);
        return {
          id: index + 1,
          title: `${batch}`,
          start,
          end,
          url: teacherUrl,
          batch,
        };
        // return {
        //   id: index + 1,
        //   title: `${batch}`, // Use batch ID in the title
        //   start,
        //   end,
        //   url: teacherUrl,
        //   batch, // Include the batch information
        // };
      });

      setEvents(exampleEvent);
    }
  }, [calender]);

  // Coloring Here
  const eventStyleGetter = (event, start, end, isSelected) => {
    const batchColors = [
      "blue",
      "red",
      "green",
      "purple",
      "orange",
      "pink",
      "cyan",
      "brown",
      "yellow",
      "magenta",
    ];

    const batchIndex = events.findIndex((e) => e.batch === event.batch);
    const color = batchColors[batchIndex % batchColors.length];

    const style = {
      backgroundColor: color,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "none",
      display: "block",
    };
    return { style };
  };

  const handleSelectSlot = (slotInfo) => {
    const selectedDate = moment(slotInfo.start).startOf("day");
    const today = moment().startOf("day");

    if (!selectedDate.isSame(today)) {
      alert("Only today's date is selectable.");
    }
  };

  const handleSelectEvent = (event, e) => {
    e.preventDefault();
    const eventDate = moment(event.start).startOf("day");
    const today = moment().startOf("day");

    if (eventDate.isSame(today)) {
      window.open(event.url, "_blank").focus();
    } else {
      alert("Only today's events are clickable.");
    }
  };

  const dateCellWrapper = ({ value, children }) => {
    const date = moment(value).startOf("day");
    const today = moment().startOf("day");

    return React.cloneElement(React.Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: date.isSame(today) ? "white" : "#f0f0f0",
        pointerEvents: date.isSame(today) ? "auto" : "none",
      },
    });
  };
  console.log("events", events);
  return (
    <div>
      {events && (
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable
          eventPropGetter={eventStyleGetter}
          views={["month", "week", "day"]}
          defaultView="week"
          popup
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          components={{ dateCellWrapper }}
        />
      )}
    </div>
  );
};

export default CounsellorCalender;
